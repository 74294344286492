import { NavLink } from "react-router-dom";

const AppIndexComponent = () => {
	return (
		<>
			<div>
				<div className="h1">Página não encontrada</div>
				<NavLink to="/app">Voltar à Página inicial</NavLink>
			</div>
		</>
	);
};

export default AppIndexComponent;
