import React, { useEffect } from "react";

import "./styles.css";

const HelpApp = () => {
	useEffect(() => {
		document.title = "Dois Vs Cashback - Ajuda";
	}, []);

	return (
		<>
			<div className="content-help-app">
				<div className="section-faq-space"></div>
				<div className="banner-center-title-app">
					Central de ajuda
					<span className="subtitle-center-app">
						Tire aqui suas dúvidas, e deixe suas sugestões.
					</span>
				</div>

				<div className="content-box-help-app">
					<section className="section-help-app">
						<div className="section-title-app">
							<i className="icon-arrow-app"></i> Como funciona?
						</div>
						<div className="section-text-content-app">
							Suponha que a Ana gerou um cashback de R$10,00 com
							sua propria compra, 1 décimo (R$1,00) retorna para
							ela mesma, 3 décimos (R$3,00) são creditados para o
							João, 2 décimos(R$2,00) são creditados para você, 4
							décimos (R$4,00) pertencem à Dois VS Cashback. Caso
							a compra seja feita pelo João, com o mesmo valor de
							cashback, ele recebe 1 décimo (R$1,00) referente a
							sua própria compra, e você recebe 3 décimos
							(R$3,00).
						</div>
					</section>

					<section className="section-help-app">
						<div className="section-title-app">
							<i className="icon-arrow-app"></i>
							Ajuda com o login e cadastro no app
						</div>
						<div className="section-text-content-app">
							Ao acessar o aplicativo Dois Vs Cashback, você será
							direcionado automaticamente para a tela de login e
							cadastro, clicando em cadastro, você deve preencher
							os seu dados como: CPF, nome, data de nascimento,
							email, senha e ID do patrocinador.
							<br />
							Após preencher os dados e ler os termos de uso e
							privacidade, clique em cadastrar para finalizar o
							seu cadastro. Você será redirecionado para a tela
							inicial do app já logado.
						</div>
					</section>

					<section className="section-help-app">
						<div className="section-title-app">
							<i className="icon-arrow-app"></i>
							Como receber o cashback do estabelecimento?
						</div>
						<div className="section-text-content-app">
							Ao efetuar uma compra em um estabelecimento parceiro
							da Dois Vs, você deverá solicitar ao atendente que
							quer receber cashback, então, o atendente deverá
							gerar um pedido de compra com o código da compra, o
							seu ID de usuário e o valor total da compra. Feito
							isso, abrirá uma tela de leitura de QR Code no
							sistema do estabelecimento. Você então, deverá gerar
							o QRCode no app, informando o código da compra
							gerada pelo comerciante e o valor. O comerciante por
							fim, deverá fazer a leitura do seu QRCode,
							finalizando assim a transação e você receberá o seu
							cashback.
						</div>
					</section>

					<section className="section-help-app">
						<div className="section-title-app">
							<i className="icon-arrow-app"></i>
							Como adicionar saldo à minha carteira?
						</div>
						<div className="section-text-content-app">
							Esta funcionalidade está temporariamente
							indisponível.
						</div>
					</section>

					<section className="section-help-app">
						<div className="section-title-app">
							<i className="icon-arrow-app"></i>
							Resgate de cashback
						</div>
						<div className="section-text-content-app">
							Para solicitar o resgate de cashback, você deverá
							acessar ao app na tela de resgate, caso não tenha
							uma conta bancária cadastrada, deverá cadastrar.
							Após feito isso, clique no botão Solicitar saque,
							informe o valor e clique em Solicitar.
							<br />
							<i>
								*Você deverá tem um saldo mínimo de R$50,00 para
								solicitar saques para a conta bancária. Taxas
								poderão ser aplicadas.
							</i>
							<i>
								As transferências podem demorar até 3 dias úteis
								para serem realizadas.
							</i>
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default HelpApp;
