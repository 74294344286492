import { useEffect, useState } from "react";

import states from "../../utils/states";

import api from "../../utils/api";

const AppConfigUpdateAddress = () => {
	const [msgError, setMsgErr] = useState("");
	const [load, setLoad] = useState(false);

	const token = localStorage.getItem("token");

	const [cep, setCEP] = useState("");
	const [street, setStreet] = useState("");
	const [num, setNum] = useState("");
	const [neighbour, setNeighbour] = useState("");
	const [city, setCity] = useState("");
	const [uf, setUF] = useState("");

	useEffect(() => {
		const getPartnerData = async () => {
			const res = await api
				.get("partner/data", {
					headers: { token: token },
				})
				.catch((error) => {
					console.log(error?.response);
				});

			if (res) {
				let address = res?.data?.address ? res?.data?.address : {};

				setCEP(address?.cep);
				setStreet(address?.street);
				setNum(address?.num);
				setNeighbour(address?.neighbour);
				setCity(address?.city);
				setUF(address?.uf);
			}
		};

		getPartnerData();
	}, [token]);

	const handleAddress = async () => {
		if (
			cep === "" ||
			street === "" ||
			num === "" ||
			city === "" ||
			uf === ""
		) {
			setMsgErr("Preencha os campos obrigatórios!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		}

		const res = await api
			.post(
				"partner/address/update",
				{
					cep: cep,
					street: street,
					num: num,
					neighbour: neighbour,
					city: city,
					uf: uf,
				},
				{
					headers: { token: token },
				}
			)
			.catch((err) => {
				setLoad(false);
			});

		let status = res?.data?.status;
		let message = res?.data?.message;

		if (status === 1) {
			setCEP(cep);
			setStreet(street);
			setNum(num);
			setNeighbour(neighbour);
			setCity(city);
			setUF(uf);

			setMsgErr(message);
		} else {
			setCEP(cep);
			setStreet(street);
			setNum(num);
			setNeighbour(neighbour);
			setCity(city);
			setUF(uf);

			setMsgErr(message);
		}

		setLoad(false);

		setTimeout(() => {
			setMsgErr("");
		}, 3000);
	};

	return (
		<>
			<div className="clear"></div>
			<div className="form-app zero-pad-top">
				<div className="u-title">Atualizar endereço</div>
				<div className="clear _5"></div>

				<input
					className="input-cep"
					type="text"
					name="cep"
					autoComplete="off"
					placeholder="CEP"
					maxLength={10}
					value={cep}
					onChange={(e) => {
						let v = e.target.value;
						setCEP(v);
					}}
				/>
				<div className="clear _10"></div>

				<div className="box-flex-address">
					<input
						type="text"
						name="address"
						autoComplete="off"
						placeholder="Rua"
						value={street}
						onChange={(e) => {
							let v = e.target.value;
							setStreet(v);
						}}
					/>

					<input
						className="input-num"
						type="text"
						name="number"
						autoComplete="off"
						placeholder="Nº"
						maxLength={8}
						value={num}
						onChange={(e) => {
							let v = e.target.value;
							setNum(v);
						}}
					/>
				</div>
				<div className="clear _10"></div>

				<input
					type="text"
					name="neighbour"
					autoComplete="off"
					placeholder="Bairro"
					value={neighbour}
					onChange={(e) => {
						let v = e.target.value;
						setNeighbour(v);
					}}
				/>

				<div className="clear _10"></div>

				<div className="box-flex-address">
					<input
						type="text"
						name="city"
						autoComplete="off"
						placeholder="Cidade"
						value={city}
						onChange={(e) => {
							let v = e.target.value;
							setCity(v);
						}}
					/>

					<select
						className="input-uf"
						name="state"
						onChange={(e) => {
							let v = e.target.value;
							setUF(v);
						}}
						value={uf}
					>
						<option value="null">UF</option>
						{states.map((st) => (
							<option key={st.code} value={st.sigla}>
								{st.name}
							</option>
						))}
					</select>
				</div>

				<div className="clear _10"></div>

				<div
					className={
						msgError !== "" ? "errorBox-components" : "errorBoxHide"
					}
				>
					{msgError}
				</div>
				<button
					disabled={load}
					onClick={() => {
						setLoad(true);
						handleAddress();
					}}
				>
					SALVAR
				</button>
			</div>
		</>
	);
};

export default AppConfigUpdateAddress;
