import React, { useEffect } from "react";

import "./styles.css";

import HeaderBar from "../HeaderBar";

import Footer from "../Footer";

import banner from "../../assets/banner_indicados.png";

const Terms = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<HeaderBar page="termos" />
			<div className="content-terms">
				<div className="title-terms">Termos de uso</div>
				<div className="text-terms">
					<div className="subtitle-terms">
						CONTRATO DE ADESÃO AO SISTEMA DE VENDAS MULTINÍVEL
					</div>
					Prezado ADERENTE, é muito importante a leitura atenta destes
					Termos no ato de seu cadastro na Dois Vs Cashback Ltda, pois
					ao se cadastrar, o ADERENTE, aceita as regras impostas pela
					EMPRESA. A adesão a Dois Vs Cashback Ltda. e seus benefícios
					são oferecidos a exclusivo critério da EMPRESA, que se
					reserva o direito de alterar as regras a qualquer momento,
					dentro dos termos legais, no todo ou em parte, mediante
					notificação prévia enviada aos ADERENTES, na forma descrita
					nos Termos de Uso:
					<br />
					<b className="section">1. Das Partes</b>
					Dois Vs Cashback Ltda., pessoa jurídica de direito privado,
					inscrita no CNPJ sob o nº 44.661.277/0001-30, sediada na Rua
					Carandaí, nº 149, sala 01, bairro Chácara, em Betim/MG, CEP:
					32670-324, neste ato representada por seu
					sócio-administrador, Wellington Luiz Alexandre, brasileiro,
					solteiro, empresário, inscrito no CPF sob o número
					631.486.506-97, domiciliado nesse município e o ADERENTE
					eletronicamente qualificado celebram as seguintes condições:
					<br />
					As partes acima qualificadas celebram o presente Contrato
					Eletrônico, que se rege pelas formas e condições deste
					instrumento, observadas as leis da República Federativa do
					Brasil.
					<br />
					<b className="section">2. Introdução.</b>A DOIS VS CASHBACK
					LTDA. agradece pelo interesse em utilizar o Portal Virtual
					totalmente desenvolvido e mantido por essa conceituada
					EMPRESA, que desenvolve e explora a tecnologia digital, por
					meio da plataforma inserida em seu domínio web –{" "}
					<span className="underline">www.doisvs.com.br</span> e pelo
					aplicativo Dois Vs Cashback, sendo essencial para a
					realização da prestação dos serviços a que se dispõe.
					<br />
					O presente Termo de Uso estabelece a relação contratual
					entre os clientes que utilizam o Site e Aplicativo Dois Vs
					Cashback, estabelecendo as regras de utilização e as
					responsabilidades de cada parte, em conformidade com a
					legislação brasileira, incluindo as disposições da Lei Nº
					12.965/14 (Marco Civil da Internet) e do Decreto Nº 8.771,
					de 11/05/16.
					<br />
					Antes de prosseguir, é fundamental que o ADERENTE leia e
					compreenda todos os Termos que regulam a utilização do
					Portal e de seus benefícios. Por isso, A Dois Vs Cashback
					criou este contrato virtual, que foi inclusive redigido de
					forma clara e em linguagem acessível.
					<br />
					<b className="section">3. A Dois Vs Cashback.</b>
					3.1. O Portal Dois Vs Cashback disponibiliza uma Plataforma
					virtual de Marketing Multinível (MMN) e divulgação de
					diversas lojas físicas e virtuais, que são chamadas, em
					conjunto, de “Anunciantes” e/ou “Parceiros”. O objetivo
					principal da Dois Vs Cashback é estabelecer parceria com
					lojas, supermercados, bares, restaurantes, padarias, enfim,
					todo tipo de comércio e prestadores de serviços, que se
					comprometem, por meio de um contrato de parceria, a devolver
					parte do valor da compra realizada pelo ADERENTE em forma de
					cashback. O Valor do cashback poderá variar entre 1,5%(um e
					meio por cento) a 30%(trinta por cento) do valor total da
					compra. O valor do cashback será pago pelo parceiro
					comercial à Dois Vs Cashback Ltda., que fará o rateio do
					mesmo em rede (MMN) conforme descrito no “item 5” do
					presente Termo.
					<br />
					3.2. A Dois Vs Cashback atua como divulgadora, veiculando
					anúncios de produtos e serviços fornecidos pelos
					Anunciantes, sendo essa, isenta de quaisquer
					responsabilidades, por compras ou transações realizadas
					entre o contratante e os Anunciantes/Parceiros.
					<br />
					3.3. Ao efetuar uma compra de um Anunciante, através do
					Aplicativo ou de um link disponibilizado no Site, o
					contratante gera uma nova e distinta relação jurídica,
					direta e exclusivamente com o anunciante.
					<br />
					3.4. Os anúncios veiculados no Portal podem conter
					informações sobre alguns produtos ou serviços, incluindo
					preço. É de responsabilidade exclusiva do ADERENTE, a
					conferência dos preços e demais informações dos produtos
					diretamente no site dos anunciantes, incluindo, informações
					e atualizações dos anúncios ou preços.
					<br />
					3.5. A responsabilidade pelos produtos e serviços e por suas
					informações, incluindo o preço, é exclusiva e integral dos
					Anunciantes/Parceiros. A Dois Vs Cashback não é proprietária
					dos Produtos e Serviços oferecidos no Portal, sendo de
					inteira responsabilidade do ADERENTE, disponibilizar espaço
					nos Meios Digitais para que os Anunciantes/Parceiros os
					divulguem aos usuários.
					<br />
					3.6. Cabe ao ADERENTE, a verificar a exatidão das
					informações dos anúncios constantes no Portal ou diretamente
					no próprio site dos Anunciantes/Parceiros. Sempre que houver
					diferença ou divergência entre os preços e demais
					informações de produtos no Portal e no site dos Anunciantes,
					prevalecerá as informações do site do Anunciante.
					<br />
					3.7. Antes de finalizar qualquer compra/contratação, o
					ADERENTE deverá ler, compreender e aceitar o regulamento
					específico de cada promoção/anúncio, para auxiliar na sua
					correta e efetiva utilização do Portal e de seus benefícios.
					<br />
					<b className="section">4. Efetivação do cadastro </b>
					São requisitos básicos para se tornar um ADERENTE ao sistema
					Marketing multinível ser maior de 18 (dezoito) anos, aceitar
					este contrato de adesão eletronicamente, ter pleno
					conhecimento das normas principais e acessórias e demais
					textos publicados no website e aplicativo da EMPRESA
					referentes ao objeto deste contrato e aos produtos e
					serviços relacionados ao mesmo, momento em que declara
					aceitar a atender a todas as regras aplicáveis.
					<br />
					4.1. Primeiramente, o ADERENTE deve fazer um cadastro no
					Site ou Aplicativo da EMPRESA, prestando algumas
					informações, as quais estarão sujeitas à análise e
					aprovação. O ADERENTE fica ciente, de que, ao se cadastrar
					no Site ou Aplicativo, autoriza que a Dois Vs Cashback,
					controlados, coligados e parceiros, consultem as suas
					informações que constem ou venham a constar no Sistema de
					Informações de Crédito, ou dos sistemas que venham a
					complementá-lo ou a substituí-lo, cuja finalidade é prover
					informações ao Banco Central do Brasil (BACEN) para
					monitoramento do crédito no sistema financeiro e
					fiscalização, bem como propiciar o intercâmbio de
					informações entre instituições financeiras sobre o montante
					de responsabilidade de seus clientes em operações de
					crédito. Todas as informações e dados pessoais fornecidos
					serão armazenados no sistema, de forma sigilosa e segura, e
					serão tratados conforme a Lei 13.709/18, Lei Geral de
					Proteção de Dados Pessoais (LGPD) e a Política de
					Privacidade da EMPRESA.
					<br />
					4.2. As informações prestadas no cadastro devem ser exatas,
					precisas e verdadeiras. O ADERENTE se compromete a atualizar
					o cadastro sempre que houver alguma alteração nos seus
					dados. A Dois Vs Cashback poderá utilizar de todos os meios
					legais para confirmar a veracidade dos dados informados pelo
					ADERENTE. A EMPRESA não se responsabiliza, por dados
					incorretos ou inverídicos informados pelo ADERENTE. O
					ADERENTE administrará seus próprios negócios, sendo único
					responsável juridicamente por seus atos, promovendo a
					indicação dos produtos e serviços da EMPRESA no mercado
					nacional, cumprindo todas as normas, regulamentos, políticas
					e procedimentos, tal como alterados periodicamente,
					inclusive aqueles estabelecidos no regulamento e demais
					publicações.
					<br />
					4.3. Após a conclusão do cadastro, O ADERENTE terá um nome
					de usuário (ID) e uma senha que permitem acesso irrestrito a
					todas as informações e funções listadas em sua conta na
					plataforma virtual da Dois Vs Cashback. Permitido,
					inclusive, solicitação de transferência do saldo acumulado
					para conta bancária.
					<span className="underline">
						O nome de usuário (ID) e a senha são de natureza
						personalíssima, o uso por terceiros, por culpa ou dolo,
						é de responsabilidade exclusiva do ADERENTE.
					</span>
					<br />
					4.4. A EMPRESA recomenda, para a segurança do ADERENTE, a
					mudança periódica da senha de acesso, substituindo por senha
					de difícil identificação por terceiros, como números de
					telefone, endereço, datas de nascimento, nomes de parentes
					etc.
					<br />
					4.5. O ADERENTE poderá se retirar do grupo a qualquer tempo,
					independente de notificação, de forma antecipada ou não, sem
					a cobrança de nenhum tipo de penalidade ou taxa
					administrativa.
					<br />
					4.6. O ADERENTE declara ao aderir que tem pleno conhecimento
					das regras de adesão e exclusão e que poderá retornar ao
					Sistema no estágio inicial, realizando nova adesão, a
					critério exclusivo da EMPRESA, sendo que tal adesão poderá
					ser feita a qualquer tempo, desde que cumpridas as regras
					estabelecidas exclusivamente pela EMPRESA para novas
					adesões.
					<br />
					4.7 O ADERENTE entende e concorda que não poderá, sobre
					quaisquer circunstâncias, vender, fazer propaganda, ou
					promover como sendo da EMPRESA, qualquer produto, serviço ou
					oportunidades de negócios não associadas diretamente a
					mesma, concorda em não utilizar os contratos da EMPRESA para
					promover a venda de outros produtos ou serviços.
					<br />
					<b className="section">5. O Cashback.</b>
					5.1. A Dois Vs Cashback oferece uma oportunidade para que o
					ADERENTE receba desconto especial na compra de produtos ou
					serviços dos Anunciantes/Parceiros da EMPRESA. O desconto
					especial é concedido diretamente pela Dois Vs Cashback, por
					meio do reembolso de parte do valor gasto pelo ADERENTE nas
					compras efetuadas através do Portal, chamado de Cashback.
					<br />
					5.2. Mecanismo de compra e sistema de bonificações A
					construção da organização financeira “rede” de cada ID se
					dará da seguinte forma: qualquer pessoa que queira se
					cadastrar deverá informar o ID do usuário que lhe apresentou
					o aplicativo, sendo, a partir daí, pertencente à rede do
					aplicativo.
					<br />
					5.3. As bonificações serão distribuídas em rede como segue:
					O valor total de cashback gerado durante o dia será
					computado e faturado diariamente (sempre ás 20h.:00min.)
					através de boleto que será emitido em favor da Dois Vs
					Cashback Ltda. Para simplificar, o mecanismo de recebimento
					do Cashback, o parceiro comercial poderá cadastrar um cartão
					autorizando o débito automático da fatura gerada durante o
					dia.
					<br />
					Exemplo:
					<br />
					Um supermercado concordou em oferecer 1,5%(um vírgula cinco
					por cento) de desconto sobre o valor total da compra do
					usuário do sistema. Nesse caso, em uma compra com valor
					total de R$300,00 (trezentos reais), o cashback será de
					R$4,50 (quatro reais e cinquenta centavos).
					<div className="box-img-indique">
						<img
							src={banner}
							width="auto"
							alt="Banner de indicados"
						/>
					</div>
					Tomando por base a ilustração acima, suponha que quem
					realizou o consumo supracitado foi a “Ana” assim, os R$4,50
					que foram gerados a partir do Cashback oferecido pelo
					supermercado serão distribuídos da seguinte maneira:
					<br />
					<br />
					<section className="section-terms-a">
						a) O valor do Cashback será fracionado em 10 partes;
						<br />
						b) Para a “Ana” será creditado <b>
							um décimo (R$0,45)
						</b>{" "}
						do Cashback de sua própria compra;
						<br />
						c) Para “Marcelo”, que foi quem apresentou o sistema
						para a ”Ana”, será creditado{" "}
						<b>três décimos (R$1,35)</b> do Cashback;
						<br />
						d) Finalmente para o “João”, que apresentou o sistema ao
						“Marcelo”, será creditado <b>
							dois décimos (R$0,90)
						</b>{" "}
						do Cashback;
						<br />
						e) Os <b>quatro décimos (R$1,80),</b> restantes,
						pertencem à Dois Vs Cashback.
					</section>
					<br />
					5.4. A condicionante para manter a conta do ADERENTE ativa e
					ter direito a usar os créditos para realizar novas compras
					e/ou solicitar transferência (pix) em dinheiro para uma
					conta bancária é que:
					<span className="underline">
						cada ID deverá consumir o valor mínimo de R$100,00(cem
						reais) por mês, em produtos e serviços ofertados pelos
						parceiros comerciais
					</span>
					ou, preferencialmente, itens de primeira necessidade no
					supermercado âncora.
					<br />
					<b>
						5.5. A EMPRESA se reserva no direito de regular e
						estabelecer sem aviso prévio os bônus e vantagens,
						passando a vigorar as novas regras a partir do momento
						de sua publicação no website da EMPRESA, sendo que todos
						os atos praticados antes das alterações, serão premiados
						conforme o modelo anterior e os atos praticados a partir
						da publicação do novo modelo, serão regidos por este,
						assim como a atualização dos preços dos produtos.
					</b>
					<br />
					<b className="section">6. Os bônus em dinheiro:</b>
					O usuário poderá solicitar a transferência para sua conta
					corrente bancária toda vez que seu saldo for maior que
					R$50,00(cinquenta reais), sendo descontados 5% (cinco por
					cento), para cobrir despesas com transferências.
					<br />
					6.1. Retenções de IRPRF – Caso o saldo de Cashback atinja o
					limite mínimo da alíquota de isenção o valor do IR será
					retido na fonte conforme tabela abaixo. Tabela do IR –
					Rendimento mensal
					<br />
					<br />
					<table
						className="table-style"
						border="0"
						bordercolor="#a4a4a4"
						cellpadding="0"
						cellspacing="0"
					>
						<thead>
							<tr>
								<td>Base de cálculo</td>
								<td>Alíquota</td>
								<td style={{ "text-align": "center" }}>
									Parcela a deduzir <br />
									do IRPF
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>a. Até R$ 1.903,98</td>
								<td>Isento</td>
								<td>R$ 0,00</td>
							</tr>
							<tr>
								<td>b. De R$ 1.903,99 até R$ 2.826,65</td>
								<td>7,5%</td>
								<td>R$ 142,80</td>
							</tr>
							<tr>
								<td>c. De R$ 2.826,66 até R$ 3.751,05</td>
								<td>15%</td>
								<td>R$ 354,80</td>
							</tr>
							<tr>
								<td>d. De R$ 3.751,06 até R$ 4.664,68</td>
								<td>22,5%</td>
								<td>R$ 636,13</td>
							</tr>
						</tbody>
					</table>
					<br />
					6.2. O valor referente aos resgates solicitados será
					creditado em dinheiro na conta bancária do ADERENTE, na área
					Minha Conta. É necessário que o CPF do titular da conta
					bancária seja o mesmo CPF cadastrado pelo ADERENTE, na Dois
					Vs Cashback. Além disso, esta conta bancária somente poderá
					estar vinculada a um único CPF cadastrado na base de dados.
					<br />
					6.3. Ao se cadastrar na Dois Vs Cashback Ltda. o usuário
					garante possuir Conta Corrente ou Poupança de sua
					titularidade, para a qual o resgate será enviado após a
					confirmação do saldo.
					<br />
					6.4. É expressamente vedada a utilização de conta-salário
					para pedidos de resgate.
					<br />
					<br />
					<b>7.</b> Por se tratar de simples TERMO DE ADESÃO EM
					MARKETING MULTINÍVEL, não haverá nenhum tipo de vínculo
					entre as partes contratantes, a não ser os regidos por este
					Instrumento, não gerando vínculo de trabalhista ou
					contratual em hipótese alguma, não havendo nenhum vínculo da
					EMPRESA com o INSS ou outro instituto de previdência, o que
					significa que as atividades desenvolvidas não implicam em
					recolhimentos previdenciários de qualquer espécie.
					<br />
					<br />
					<b>8.</b> Com a ADESÃO AO PRESENTE CONTRATO, o associado
					CONCEDE EXPRESSAMENTE permissão do uso da sua imagem na
					atividade de promoção da EMPRESA, sem veicular dados
					pessoais, RENUNCIANDO A QUALQUER INDENIZAÇÃO pelo uso da
					imagem e ou do texto autorizado.
					<br />
					<b className="section">9. Do aceite</b>
					Ao clicar em "aceitar" ao final deste contrato, o ADERENTE
					estará legalmente vinculado a todos os termos e condições
					aqui presentes. Dúvidas sobre o conteúdo do presente Termo,
					poderão ser sanadas pelo suporte, por meio do contato de
					E-mail:&nbsp;
					<a
						className="mail-terms"
						href="mailto:doivscashback@gmail.com"
					>
						doivscashback@gmail.com
					</a>
					<br />
					<b className="section">10. Do foro</b>
					As partes elegem o Foro da Comarca de Betim, Estado de Minas
					Gerais, com renúncia de qualquer outro, por mais
					privilegiado que seja, para dirimir quaisquer dúvidas ou
					questões oriundas do presente contrato. E, por estarem assim
					justas e contratadas, as partes assinam o presente contrato
					digitalmente, produzindo os mesmos efeitos jurídicos de um
					contrato formal e expresso, diante da leitura integral de
					todo o conteúdo exigido para a abertura da tecla do aceite
					final.
					<br />
					<br />
					<br />
					<br />
					Betim, 01 de Fevereiro, de 2022.
				</div>
			</div>

			<Footer />
		</>
	);
};

export default Terms;
