import React from "react";
import "./styles.css";

const NotFound = () => {
	return (
		<div className="main main-not-found">
			<div className="text-not-found">
				Página não encontrada
				<div className="link-back">
					<a href="./">Voltar à página inicial</a>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
