import React, { createContext, useMemo, useState } from "react";

import api from "../utils/api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);

	useMemo(() => {
		const getUser = localStorage.getItem("user");
		if (getUser) {
			try {
				setUser(JSON.parse(getUser));
			} catch (err) {}
		}
	}, []);

	const Login = async (email, password) => {
		const res = await api
			.post("sessions/partner", {
				email: email,
				password: password,
			})
			.catch((err) => {
				return { data: { status: 400, message: err } };
			});

		return res;
	};

	const Logout = () => {
		setUser(null);

		localStorage.removeItem("token");
		localStorage.removeItem("user");
	};

	const checkToken = async (code, token) => {
		const res = await api
			.get("check/token", {
				params: {
					code: code,
					token: token,
				},
			})
			.catch((err) => {
				return false;
			});

		return res?.data;
	};

	return (
		<>
			<AuthContext.Provider
				value={{
					signed: !!user,
					user,
					Login,
					Logout,
					setUser,
					checkToken,
				}}
			>
				{children}
			</AuthContext.Provider>
		</>
	);
};
