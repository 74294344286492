import { useEffect, useState } from "react";

import api from "../../utils/api";

const AppReportsWithDraw = () => {
	const [list, setList] = useState([]);

	const token = localStorage.getItem("token");

	useEffect(() => {
		const getTransactions = async () => {
			const res = await api
				.get("withdraws", {
					headers: { token: token },
				})
				.catch((error) => {
					console.log(error?.response);
				});

			if (res) {
				let withdraws = res?.data?.withdraws;
				setList(withdraws);
			}
		};
		getTransactions();
	}, [token]);

	return (
		<>
			<div className="table-responsive">
				<table className="table table-bordered" id="dtRedeem">
					<thead>
						<tr>
							<th>Data</th>
							<th>Banco</th>
							<th>Agência</th>
							<th>Conta</th>
							<th>Titular Conta</th>
							<th>Cpf/Cnpj</th>
							<th>Saldo anterior</th>
							<th>Valor Resgate</th>
							<th>Taxa</th>
							<th>Status</th>
							<th>Ações</th>
						</tr>
					</thead>
					<tbody id="tbodyRedeem">
						{list !== null && list.length === 0 ? (
							<tr key="0">
								<td colSpan="12" className="text-align">
									Nenhum registro encontrado.
								</td>
							</tr>
						) : (
							list.map((wd) => (
								<tr key={wd.id}>
									<th>{wd.date_fmt}</th>
									<th>{wd.bank}</th>
									<th>{wd.agency}</th>
									<th>{wd.account}</th>
									<th>{wd.titular}</th>
									<th>{wd.doc}</th>
									<th>R${wd.fmt_previous_balance}</th>
									<th>R${wd.fmt_value}</th>
									<th>R$0,00</th>
									<th>{wd.status_pix}</th>
									<th>-</th>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default AppReportsWithDraw;
