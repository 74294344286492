import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./styles.css";

import { ReactComponent as Logo } from "../../assets/logo_login.svg";

const Recovery = () => {
	const base = "";

	const [code, setCode] = useState("");
	const [new_password, setNewPassword] = useState("");
	const [retype_password, setRetypePassword] = useState("");
	const [msgError, setMsgErr] = useState("");
	const [load, setLoad] = useState(false);

	const updatePassword = async () => {
		if (code === "" || new_password === "" || retype_password === "") {
			setMsgErr("Preencha os campos obrigatórios!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		}
	};

	return (
		<>
			<div className="content-login">
				<div className="box-login">
					<div className="logo-box-login">
						<Logo height={100} />
					</div>
					<div className="title-login">Redefina sua senha</div>
					<div className="clear _10"></div>
					<input
						type="text"
						name="code"
						inputMode="numeric"
						autoComplete="off"
						placeholder="Código da solicitação"
						value={code}
						onChange={(e) => setCode(e.target.value)}
					/>

					<div className="clear _5"></div>

					<input
						type="password"
						name="new_pass"
						inputMode="text"
						autoComplete="off"
						placeholder="Nova Senha"
						value={new_password}
						onChange={(e) => setNewPassword(e.target.value)}
					/>

					<div className="clear _5"></div>

					<input
						type="password"
						name="new_pass"
						inputMode="text"
						autoComplete="off"
						placeholder="Repetir Senha"
						value={retype_password}
						onChange={(e) => setRetypePassword(e.target.value)}
					/>

					<div className="clear _20"></div>

					<div
						className={
							msgError !== "" ? "errorBox" : "errorBoxHide"
						}
					>
						{msgError}
					</div>

					<button
						disabled={load}
						onClick={() => {
							setLoad(true);
							updatePassword();
						}}
					>
						ATUALIZAR
					</button>
					<div className="clear _20"></div>

					<div className="link-signup">
						<NavLink to={base + "/app/"}>Voltar</NavLink>
					</div>
				</div>
				<div className="box-icon"></div>
			</div>
		</>
	);
};

export default Recovery;
