import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import "./styles.css";

import { ReactComponent as Logo } from "../../assets/logo_login.svg";

import { AuthContext } from "../../services/auth";

import AppLogged from "../AppLogged";

const App = () => {
	const base = "";

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [msgError, setMsgErr] = useState("");
	const [load, setLoad] = useState(false);

	const [show, setShowModal] = useState(false);
	const [email_recovery, setEmailRecovery] = useState("");
	const [msgErrorEmail, setMsgErrEmail] = useState("");
	const [load_email, setLoadEmail] = useState(false);

	const handleEmailRecovery = async () => {
		if (email_recovery === "") {
			setMsgErrEmail("O email não pode ficar vazio!");
			setLoadEmail(false);

			setTimeout(() => {
				setMsgErrEmail("");
			}, 3000);
			return;
		}
	};

	const ref = useRef(null);

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setShowModal(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	const { signed, Login, setUser } = useContext(AuthContext);

	const entrar = async () => {
		if (email === "" || password === "") {
			setMsgErr("Preencha os campos de login e senha!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);
			return;
		}

		let res = await Login(email, password);

		let status = res?.data?.status;
		let message = res?.data?.message;
		let token = res?.data?.token;
		let res_user = res?.data?.user !== undefined ? res?.data?.user : null;

		if (status === 0) {
			setMsgErr(message);
		} else if (status === 1) {
			localStorage.setItem("user", res_user);
			localStorage.setItem("token", token);

			setTimeout(() => {
				let parse = JSON.parse(res_user);
				setUser(parse);
			}, 100);
		} else if (status === 400) {
			setMsgErr("Não foi possível completar a requisição!");
		} else {
			setMsgErr("Erro não definido!");
		}
		setLoad(false);

		setTimeout(() => {
			setMsgErr("");
		}, 4000);
	};

	if (!signed) {
		return (
			<div>
				<div id="box" className={!show ? "box-block" : "box-flex"}>
					<div className="popup-recovery" ref={ref}>
						<div className="class-title-recovery">
							Digite seu email cadastrado, e enviaremos um link
							para recuperação da sua senha.
						</div>
						<div className="form-input-email">
							<input
								type="text"
								name="email"
								inputMode="email"
								autoComplete="off"
								placeholder="Digite seu email"
								value={email_recovery}
								onChange={(e) =>
									setEmailRecovery(e.target.value)
								}
							/>

							<div className="clear _10"></div>

							<div
								className={
									msgErrorEmail !== ""
										? "errorBox"
										: "errorBoxHide"
								}
							>
								{msgErrorEmail}
							</div>

							<div className="box-button">
								<button
									disabled={load_email}
									onClick={() => {
										handleEmailRecovery();
									}}
								>
									ENVIAR
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="content-login">
					<div className="box-login">
						<div className="logo-box-login">
							<Logo height={100} />
						</div>
						<div className="title-login">Faça login</div>
						<input
							type="text"
							name="login"
							inputMode="email"
							autoComplete="off"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>

						<input
							type="password"
							name="_pass"
							inputMode="text"
							autoComplete="off"
							placeholder="Senha"
							disabled={load}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									setLoad(true);
									entrar();
								}
							}}
						/>

						<div className="recover-login">
							<span
								className="link-recovery"
								onClick={() => {
									setShowModal(!show);
								}}
							>
								Esqueceu sua senha?
							</span>
						</div>

						<div className="clear _20"></div>

						<div
							className={
								msgError !== "" ? "errorBox" : "errorBoxHide"
							}
						>
							{msgError}
						</div>

						<button
							disabled={load}
							onClick={() => {
								setLoad(true);
								entrar();
							}}
						>
							ENTRAR
						</button>
						<div className="clear _20"></div>

						<div className="link-signup">
							Não possui cadastro?
							<NavLink to={base + "/app/cadastro"}>
								Cadastre-se
							</NavLink>
						</div>
					</div>
					<div className="box-icon"></div>
				</div>
			</div>
		);
	}

	return <AppLogged />;
};

export default App;
