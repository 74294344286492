const AppConfigPayment = () => {
	return (
		<>
			<div className="clear _10"></div>
			<div className="form-app zero-pad-top">
				<div className="aviso-billing">
					Sempre que o valor total do cashback oferecido pelo
					parceiro, alcançar o valor de R$200,00 (duzentos reais) será
					emitido um boleto em favor da <b>Dois Vs Cashback Ltda </b>
					com vencimento para as próximas 72 horas. Você também pode
					optar por pagar automaticamente (é necessário adicionar um
					cartão de crédito).
				</div>
				<div className="clear _20"></div>
				<div className="label-box">
					<input
						type="checkbox"
						name="billing"
						id="chk"
						className="checkbox"
					/>
					<label htmlFor="chk">Cobrar fatura automaticamente</label>
				</div>

				<div className="clear _30"></div>
				<button className="btn-add-creditcard">
					ADICIONE UM CARTÃO DE CRÉDITO
				</button>
			</div>
		</>
	);
};

export default AppConfigPayment;
