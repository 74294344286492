const states = [
	{
		code: 1,
		sigla: "AC",
		name: "Acre",
	},
	{
		code: 2,
		sigla: "AL",
		name: "Alagoas",
	},
	{
		code: 3,
		sigla: "AP",
		name: "Amapá",
	},
	{
		code: 4,
		sigla: "AM",
		name: "Amazonas",
	},
	{
		code: 5,
		sigla: "BA",
		name: "Bahia",
	},
	{
		code: 6,
		sigla: "CE",
		name: "Ceará",
	},
	{
		code: 7,
		sigla: "DF",
		name: "Distrito Federal",
	},
	{
		code: 8,
		sigla: "ES",
		name: "Espírito Santo",
	},
	{
		code: 9,
		sigla: "GO",
		name: "Goiás",
	},
	{
		code: 10,
		sigla: "MA",
		name: "Maranhão",
	},
	{
		code: 11,
		sigla: "MT",
		name: "Mato Grosso",
	},
	{
		code: 12,
		sigla: "MS",
		name: "Mato Grosso do Sul",
	},
	{
		code: 13,
		sigla: "MG",
		name: "Minas Gerais",
	},
	{
		code: 14,
		sigla: "`PB",
		name: "Paraíba",
	},
	{
		code: 15,
		sigla: "PR",
		name: "Paraná",
	},
	{
		code: 16,
		sigla: "PA",
		name: "Pará",
	},
	{
		code: 17,
		sigla: "PE",
		name: "Pernambuco",
	},
	{
		code: 18,
		sigla: "PI",
		name: "Piauí",
	},
	{
		code: 19,
		sigla: "RJ",
		name: "Rio de Janeiro",
	},
	{
		code: 20,
		sigla: "RN",
		name: "Rio Grande do Norte",
	},
	{
		code: 21,
		sigla: "RS",
		name: "Rio Grande do Sul",
	},
	{
		code: 22,
		sigla: "RO",
		name: "Rondônia",
	},
	{
		code: 23,
		sigla: "RR",
		name: "Roraima",
	},
	{
		code: 24,
		sigla: "SC",
		name: "Santa Catarina",
	},
	{
		code: 25,
		sigla: "SP",
		name: "São Paulo",
	},
	{
		code: 26,
		sigla: "SE",
		name: "Sergipe",
	},
	{
		code: 27,
		sigla: "TO",
		name: "Tocantins",
	},
];

export default states;
