import React, { useEffect } from "react";

import "./styles.css";

import HeaderBar from "../HeaderBar";

import Footer from "../Footer";

const Privacy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<HeaderBar page="privacidade" />
			<div className="content-privacy">
				<div className="title-privacy">Política de privacidade</div>
				<div className="text-privacy">
					<div className="subtitle-privacy">
						COMPROMISSO DOIS Vs CASHBACK
					</div>
					A Dois Vs Cashback tem o compromisso com a transparência, a
					privacidade e a segurança dos dados de seus usuários durante
					todo o processo de interação com nosso site, aplicativo e da
					plataforma. Para que entendam melhor quais informações
					coletamos e como as utilizamos, armazenamos ou excluímos,
					detalhamos a seguir nossa Política de Privacidade.
					<br />
					Os dados cadastrais dos clientes não são divulgados para
					terceiros, exceto quando necessários para o processo de
					cobrança, atendimento ao consumidor, disponibilização de
					benefícios, promoções e pesquisas de satisfação.
					<br />
					<br />
					Para que a Política de Privacidade seja bem compreendida, é
					fundamental esclarecer alguns conceitos importantes:
					<br />
					<section className="section-privacy-a">
						<b>- Usuário –</b> toda pessoa física que utilizará a
						plataforma e o aplicativo.
						<br />
						<b>- Dados pessoais –</b> qualquer informação
						relacionada a uma pessoa que a identifique ou que, usada
						em combinação com outras informações tratadas,
						identifique um indivíduo. Ainda, qualquer informação por
						meio da qual seja possível identificar uma pessoa ou
						entrar em contato com ela.
						<br />
						<b>- Tratamento de dados pessoais –</b> considera-se
						tratamento de dado pessoais a coleta, produção,
						recepção, classificação, utilização, acesso, reprodução,
						transmissão, distribuição, processamento, arquivamento,
						armazenamento, eliminação, avaliação ou controle da
						informação, comunicação, transferência, difusão ou
						extração de dados de pessoas físicas.
						<br />
						<b>- Titular de dados –</b> qualquer pessoa física que
						tenha seus dados pessoais tratados pela Dois Vs
						Cashback;
						<br />
						<b>- Finalidade –</b> o que queremos alcançar com o
						tratamento de dados pessoais.
						<br />
						<b>- Necessidade –</b> o tratamento de dados pessoais
						deve se limitar ao mínimo necessário para o propósito
						almejado. Ou seja, deve ser pertinente, proporcional e
						não excessivo.
						<br />
						<b>- Consentimento –</b> autorização clara e objetiva
						que o titular dá para tratamento de seus dados pessoais
						com finalidade previamente estipulada. Após dar o
						consentimento, você pode revogá-lo a qualquer momento. A
						revogação não cancela os processamentos realizados
						previamente.
					</section>
					<br />
					<b className="section">
						1. A quem essa política de privacidade se aplica?
					</b>
					Aplica-se a todos os usuários da plataforma e do aplicativo
					Dois Vs Cashback, que de alguma forma tenham os dados
					pessoais tratados por nós.
					<br />
					<b className="section">
						2. Que tipo de informações pessoais coletamos e
						utilizamos?
					</b>
					A Dois Vs Cashback coleta e armazena os seguintes tipos de
					informações:
					<br />
					<br />
					<b>Informações que o usuário nos fornece:&nbsp;</b>
					A Dois Vs Cashback coleta as informações que o usuário
					fornece quando utiliza nossa plataforma, inclusive quando
					cria uma conta no nosso aplicativo. Isso pode incluir nome
					completo, e-mail, CPF, cópia de alguns documentos para fins
					de autenticação de identidade, endereço, números de
					telefone, data de nascimento, número do cartão de crédito e
					o número da conta bancária. A Dois Vs Cashback poderá
					solicitar dados sensíveis, como biometria e fotos, para fins
					de prevenção à fraudes e garantia de segurança aos serviços
					contratados. Podemos coletar informações adicionais sobre o
					usuário quando este se comunica conosco, através do Serviço
					de Atendimento ao Cliente (SAC), ou quando responde a uma
					pesquisa.
					<br />
					<b>
						Informações sobre transações realizadas em nossa
						plataforma:&nbsp;
					</b>
					Quando o usuário utiliza a plataforma para compras ou outras
					transações financeiras, são coletadas informações sobre a
					transação que estiver sendo efetuada, tais como número do
					cartão de crédito ou débito, dados de conta e autenticação,
					bem como detalhes de cobrança, entrega e contato.
					<br />
					<b>Informações coletadas pelo dispositivo móvel:&nbsp;</b>
					Quando o usuário baixa ou utiliza o aplicativo da Dois Vs
					Cashback, podemos coletar dados sobre a localização do seu
					dispositivo móvel. Podemos utilizar essas informações para
					fornecer serviços de localização, como anúncios, resultados
					de buscas e outros conteúdos personalizados. A maioria dos
					dispositivos móveis permite que o usuário desligue os
					serviços de localização. Essa função poderá ser desativada
					através do painel de configurações do app.
					<br />
					<b>
						Informações sobre o usuário através de outras
						fontes:&nbsp;
					</b>
					Podemos obter informações de terceiros, com outros usuários
					da Dois Vs Cashback quando, por exemplo, você recebe
					pagamentos ou transferências pela plataforma; de fontes
					públicas, por exemplo, dados demográficos; agências ou
					órgãos de crédito, por exemplo, relatórios de
					crédito/cheques; provedores de dados, por exemplo, dados
					demográficos relacionados a anúncios publicitários on-line e
					com base nos interesses do Cliente; e de empresas que vendem
					serviços com o meio de pagamento Dois Vs Cashback. Nós
					também podemos obter informações sobre o usuário através de
					empresas parceiras para confirmação de dados e informações
					fornecidas, dentre outras finalidades indicadas nesta
					política.
					<br />
					<b className="section">
						3. Por que solicitamos seus dados pessoais?
					</b>
					Os dados são necessários para:
					<section className="section-privacy-items">
						• entregar o serviço contratado;
						<br />• otimizar a interação entre usuário e plataforma;
						<br />• garantir a segurança do site, seu aplicativo,
						plataforma e dados que processa;
						<br />• informar ao usuário sobre as ofertas e divulgar
						os nossos serviços;
						<br />• cumprir obrigações legais;
						<br />• fins administrativos e de gestão.
						<br />
					</section>
					O fornecimento dos dados permite:
					<section className="section-privacy-items">
						• entregar os serviços adquiridos;
						<br />• coordenar com parceiros o gerenciamento de
						pagamento de serviços ou produtos adquiridos através da
						Dois Vs Cashback;
						<br />• prestar serviços adequados às necessidades do
						usuário;
						<br />• melhorar a experiência do usuário na Dois Vs
						Cashback;
						<br />• fazer análises e pesquisas de mercado;
						<br />• manter o usuário informado sobre os produtos e
						serviços que oferecemos;
						<br />• executar publicidade online direcionada;
						<br />• prevenir, detectar e investigar atividades que
						estejam em desacordo com o nosso Código de Conduta ou
						que sejam proibidas ou ilegais;
						<br />• melhorar nosso site, aplicativo e plataforma.
					</section>
					<br />A Dois Vs Cashback utiliza bases legais, que podem
					variar de acordo com a finalidade da coleta, para tratar os
					dados pessoais dos usuários. O prazo de armazenamento poderá
					mudar conforme a base legal aplicável à cada situação e a
					finalidade.
					<br />
					<b className="section">3.1 serviços de marketing:</b>
					Os serviços de marketing são oferecidos por meio de
					comunicações gratuitas sobre ofertas dirigidas ao usuário,
					relacionadas ao seu perfil no site e à compras on-line
					efetuadas. Esse serviço abrange e-mails, notificações e SMS.
					Vale ressaltar que o usuário poderá cancelar o serviço, a
					qualquer momento, acessando o Painel de Configurações em
					Perfil/Minha Conta.
					<br />
					<b className="section">
						4. Com quem compartilhamos seus dados?
					</b>
					As informações coletadas são tratadas dentro da Dois Vs
					Cashback, e somente serão compartilhadas quando forem
					necessárias:
					<section className="section-privacy-items">
						(i) para adequada prestação dos serviços objeto de suas
						atividades com empresas parceiras;
						<br />
						(ii) para proteção em caso de conflito;
						<br />
						(iii) mediante decisão judicial ou requisição de
						autoridade competente;
						<br />
						(iv) com empresas provedoras de infraestrutura
						tecnológica e operacional, como empresas intermediadoras
						de pagamento e provedoras de serviço de armazenamento de
						informações.
					</section>
					<b className="section">
						5. Transferência internacional de dados
					</b>
					Como a internet é um ambiente global, determinados serviços
					oferecidos pela Dois Vs Cashback podem demandar a
					transferência dos seus dados para outros países.
					<br />
					<br />
					Nesses casos, os dados são tratados de acordo com a LGPD
					(Lei Geral de Proteção de Dados) e demais legislações de
					proteção. Tomamos medidas de segurança de acordo com nossas
					políticas e adotamos cláusulas-padrão nos contratos com
					fornecedores e prestadores de serviço.
					<br />
					<br />
					Ao utilizar a plataforma, aplicativo e/ou se comunicar
					conosco, você concorda com o tratamento de informações,
					inclusive a transferência internacional de dados, quando
					necessário. Adotamos medidas para garantir que quaisquer
					informações coletadas sejam tratadas com segurança, conforme
					os padrões de proteção de dados e de acordo com esta
					Política de Privacidade.
					<br />
					<b className="section">
						6. Por quanto tempo armazenamos informações pessoais?
					</b>
					Armazenamos as informações dos Clientes de acordo com as
					normas de prescrição do Direito brasileiro.
					<br />
					<b className="section">
						7. Quais são os direitos do titular de dados?
					</b>
					O titular dos dados pessoais possui o direito de obter da
					Dois Vs Cashback, a qualquer momento, mediante requisição
					formal, informações referentes aos seus dados.
					<br />
					<br />A Dois Vs Cashback terá 15 dias para responder às
					solicitações dos titulares. Os pedidos serão analisados
					conforme previsto na legislação vigente e, por questões
					legais, algumas solicitações, podem não ser atendidas. Os
					titulares dos dados, segundo o texto da LGPD, podem exercer
					os seus direitos por meio de:
					<section className="section-privacy-items">
						I - Confirmação da existência de tratamento;
						<br />
						II - Acesso aos dados;
						<br />
						III - Correção de dados incompletos, inexatos ou
						desatualizados;
						<br />
						IV - Anonimização, bloqueio ou eliminação de dados
						desnecessários, excessivos ou tratados em
						desconformidade com o disposto nesta Lei;
						<br />V - Portabilidade dos dados a outro fornecedor de
						serviço ou produto, mediante requisição expressa, de
						acordo com a regulamentação da autoridade nacional,
						observados os segredos comercial e industrial;
						<br />
						VI - Eliminação dos dados pessoais tratados com o
						consentimento do titular;
						<br />
						VII - informação das entidades públicas e privadas com
						as quais o controlador realizou uso compartilhado de
						dados;
						<br />
						VIII - informação sobre a possibilidade de não fornecer
						consentimento e sobre as consequências da negativa;
						<br />
						IX - Revogação do consentimento.
					</section>
					<b className="section">8. Como exercer os seus direitos?</b>
					Você pode exercer seus direitos acessando a seção “Política
					de Privacidade”, no “Perfil/Minha Conta” do aplicativo Dois
					Vs Cashback. Além disso, pode entrar em contato pelo
					E-mail:&nbsp;
					<a
						className="mail-terms"
						href="mailto:privacidade@doisvs.com.br"
					>
						privacidade@doisvs.com.br
					</a>
					<br />
					<br />
					Para mudar suas preferências em relação às notificações
					(incluindo as comunicações de marketing), a qualquer
					momento, você pode acessar o Painel de Configurações em
					Perfil/Minha Conta. Se não quiser receber nossas
					comunicações de marketing, também pode cancelar a assinatura
					clicando no link enviado junto com o e-mail do marketing.
					<br />
					<b className="section">
						9. Como você pode manter suas informações pessoais
						seguras?
					</b>
					A Dois Vs Cashback utiliza os melhores protocolos de
					segurança para preservar a privacidade dos dados dos
					usuários, mas também recomenda medidas de proteção
					individual. Para manter a segurança e proteção dos dados
					pessoais fornecidos no cadastro, em nenhuma hipótese o login
					e senha devem ser compartilhados com terceiros. Além disso,
					ao acessar o cadastro pelo “Perfil/Minha Conta”,
					principalmente em computadores públicos, certifique-se de
					que foi realizado o logout da conta para evitar que pessoas
					não autorizadas acessem e utilizem as informações sem o seu
					conhecimento. Não entramos em contato por telefone,
					WhatsApp, SMS ou e-mail solicitando dados pessoais. Em
					nenhuma hipótese eles devem ser fornecidos, pois pode ser
					uma tentativa de uso indevido. Para mais informações, você
					pode consultar nosso Guia de segurança.
					<br />
					<b className="section">
						10. Outras informações importantes sobre proteção de
						dados
					</b>
					Para garantir que as regras estão claras e precisas, podemos
					alterar a Política a qualquer momento, publicando a Política
					de Privacidade revisada no site e indicando a data de
					efetivação do documento. Se alguma mudança substancial for
					feita, você receberá uma notificação por e-mail.
					<br />
					<b className="section">
						11. Como entrar em contato com o encarregado da proteção
						de dados da Dois Vs Cashback?
					</b>
					O encarregado da proteção de dados é o responsável escolhido
					pela Dois Vs Cashback para atuar como canal de comunicação
					entre o controlador, os titulares dos dados e a Autoridade
					Nacional de Proteção de Dados (ANPD). Qualquer dúvida sobre
					tratamento de dados pessoais poderá ser encaminhada para o
					E-mail:&nbsp;
					<a
						className="mail-terms"
						href="mailto:privacidade@doisvs.com.br"
					>
						privacidade@doisvs.com.br
					</a>
					<br />
					<b className="section">12. Legislação e foro</b>
					Esta política será regida, interpretada e executada de
					acordo com as Leis da República Federativa do Brasil,
					especialmente a Lei nº 13.709/2018, independentemente das
					Leis de outros estados ou países, sendo competente o foro de
					domicílio do Usuário para dirimir quaisquer dúvidas
					decorrentes deste documento.
				</div>
			</div>

			<Footer />
		</>
	);
};

export default Privacy;
