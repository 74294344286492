import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import "./styles.css";

import logo_app from "../../assets/logo_app.svg";

import AppIndexComponent from "../../components/AppIndexComponent";
import AppQRCode from "../../components/AppQRCode";
import AppWithDraw from "../../components/AppWithDraw";
import AppIndicate from "../../components/AppIndicate";
import AppSubIndicate from "../../components/AppSubIndicate";
import AppReports from "../../components/AppReports";
import AppConfig from "../../components/AppConfig";
import ModalShare from "../../components/ModalShare";

import { AuthContext } from "../../services/auth";

import api from "../../utils/api";

const AppLogged = () => {
	const params = useParams();
	let _base = "/app";

	const { signed, user, Logout, checkToken } = useContext(AuthContext);

	const [loading, setLoading] = useState(false);
	const [userData, setData] = useState();
	const [showMenu, setShowMenu] = useState(false);
	const [show, setShowModal] = useState(false);
	const [cashValue, setCashValue] = useState(0);
	const ref = useRef(null);

	const [msgErr, setMsgErr] = useState("");
	const [load, setLoad] = useState(false);

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setShowModal(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	const getUser = localStorage.getItem("user");
	const token = localStorage.getItem("token");
	let pUser = JSON.parse(getUser);

	checkToken(pUser?.code, token)
		.then((res) => {
			if (res?.status !== 1) {
				Logout();
			}
		})
		.catch((err) => {
			Logout();
		});

	useMemo(() => {
		setLoading(true);
		const getPartnerData = async () => {
			const res = await api
				.get("partner/data", {
					headers: { token: token },
				})
				.catch((error) => {
					console.log(error?.response);
					setLoading(false);
				});
			if (res) {
				setData(res?.data);
				setLoading(false);
			}
		};
		getPartnerData();
	}, [token]);

	let cash_balance = userData?.balance;
	let formatedvalue =
		typeof cash_balance === "number"
			? cash_balance
					.toFixed(2)
					.replace(".", ",")
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
			: "--,--";

	let cash_value = userData?.settings?.cashback
		? userData?.settings?.cashback
		: "";

	var inputLeft = document.querySelector(".min");
	var thumbLeft = document.querySelector(".thumb.left");
	var range_min = document.querySelector(".range_min");

	useEffect(() => {
		function setLeftValue() {
			var min = inputLeft !== null ? parseFloat(inputLeft.min) : 0;
			var max = inputLeft !== null ? parseFloat(inputLeft.max) : 30;

			var inputValue = inputLeft !== null ? inputLeft.value : 2.5;
			setCashValue(parseFloat(inputValue));

			if (range_min !== null) {
				range_min.innerHTML = inputValue + "%";
			}
			var percent = ((inputValue - min) / (max - min)) * 100;
			if (thumbLeft !== null) {
				thumbLeft.style.left = percent + "%";
			}
		}

		if (loading === false) {
			setLeftValue();

			if (inputLeft !== null && thumbLeft !== null) {
				inputLeft.addEventListener("input", setLeftValue);
				inputLeft.addEventListener("mouseover", function () {
					thumbLeft.classList.add("hover");
				});
				inputLeft.addEventListener("mouseout", function () {
					thumbLeft.classList.remove("hover");
				});
			}
		}
	}, [inputLeft, thumbLeft, range_min, loading]);

	const handleCashback = async () => {
		if (cashValue < 2 || cashValue > 30) {
			setMsgErr("Valor precisa estar entre 2 e 30%!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);
			return;
		}

		const res = await api
			.post(
				"partner/update/cashback",
				{
					cashback: cashValue,
				},
				{
					headers: { token: token },
				}
			)
			.catch((err) => {
				setLoad(false);
			});

		let status = res?.data?.status;
		let message = res?.data?.message;

		if (status === 1) {
			window.location.reload(false);
			setMsgErr(message);
		} else {
			setMsgErr(message);
		}

		setLoad(false);

		setTimeout(() => {
			setMsgErr("");
		}, 3000);
	};

	let path =
		params.path !== undefined && params.path !== ""
			? params?.path
			: "index";
	if (path === "configuracoes") {
		path = "config";
	}

	if (signed !== undefined && !signed) {
		window.location = _base;
		return null;
	}

	let Component = AppIndexComponent;

	switch (path) {
		case "qrcode":
			Component = AppQRCode;
			break;
		case "resgatar":
			Component = AppWithDraw;
			break;
		case "indicados":
			Component = AppIndicate;
			break;
		case "indicado":
			Component = AppSubIndicate;
			break;
		case "relatorios":
			Component = AppReports;
			break;
		case "config":
			Component = AppConfig;
			break;

		default:
			Component = AppIndexComponent;
			break;
	}

	let date = new Date();
	let year = date.getFullYear();

	return (
		<div>
			<span
				className={
					showMenu ? "menu-responsivo-checked" : "menu-responsivo"
				}
			>
				<span className="icon-close-menu">
					<label
						htmlFor="box-menu"
						onClick={() => {
							setShowMenu(!showMenu);
						}}
					></label>
				</span>
				<div className="clear _30"></div>

				<ul className="style-menu-app-resp">
					<span className="item-menu-responsivo">
						<li className={path === "index" ? "selected" : ""}>
							<NavLink to={_base}>Início</NavLink>
						</li>
					</span>

					<span className="item-menu-responsivo">
						<li className={path === "qrcode" ? "selected" : ""}>
							<NavLink to={_base + "/qrcode"}>
								Gerar QRCode
							</NavLink>
						</li>
					</span>

					<span className="item-menu-responsivo">
						<li className={path === "resgatar" ? "selected" : ""}>
							<NavLink to={_base + "/resgatar"}>Resgatar</NavLink>
						</li>
					</span>

					<span className="item-menu-responsivo">
						<li className={path === "indicados" ? "selected" : ""}>
							<NavLink to={_base + "/indicados"}>
								Meus indicados
							</NavLink>
						</li>
					</span>

					<span className="item-menu-responsivo">
						<li className={path === "relatorios" ? "selected" : ""}>
							<NavLink to={_base + "/relatorios"}>
								Relatórios
							</NavLink>
						</li>
					</span>

					<span className="item-menu-responsivo">
						<li className={path === "config" ? "selected" : ""}>
							<NavLink to={_base + "/configuracoes"}>
								Configurações
							</NavLink>
						</li>
					</span>

					<div className="clear _30"></div>
					<span className="item-menu-responsivo">
						<NavLink
							to={_base + "/"}
							onClick={() => {
								Logout();
							}}
							className="sair-resp"
						>
							sair
						</NavLink>
					</span>
				</ul>
			</span>

			<div className="header-app">
				<div className="logo-centro">
					<NavLink to={_base + "/"}>
						<img
							src={logo_app}
							height={70}
							alt="Dois Vs Cashback"
						/>
					</NavLink>
				</div>

				<span className="menu-container">
					<label
						htmlFor="box-menu"
						onClick={() => {
							setShowMenu(!showMenu);
						}}
					></label>
				</span>
			</div>

			<div className="main-container">
				<div className="main-menu">
					<div className="content-box-user-menu">
						<div className="app-nome-user">
							{user?.company}
							<div
								className="app-content-email"
								title={user?.email}
							>
								{user?.email}
							</div>
							<NavLink
								to={_base + "/"}
								onClick={() => {
									Logout();
								}}
								className="sair"
							>
								sair
							</NavLink>
						</div>
					</div>

					{/*<!-- ITENS DO MENU --> */}
					<ul className="style-menu-app">
						<li className={path === "index" ? "selected" : ""}>
							<NavLink to={_base}>Início</NavLink>
						</li>

						<li className={path === "qrcode" ? "selected" : ""}>
							<NavLink to={_base + "/qrcode"}>
								Gerar QRCode
							</NavLink>
						</li>

						<li className={path === "resgatar" ? "selected" : ""}>
							<NavLink to={_base + "/resgatar"}>Resgatar</NavLink>
						</li>

						<li className={path === "indicados" ? "selected" : ""}>
							<NavLink to={_base + "/indicados"}>
								Meus indicados
							</NavLink>
						</li>

						<li className={path === "relatorios" ? "selected" : ""}>
							<NavLink to={_base + "/relatorios"}>
								Relatórios
							</NavLink>
						</li>

						<li className={path === "config" ? "selected" : ""}>
							<NavLink to={_base + "/configuracoes"}>
								Configurações
							</NavLink>
						</li>
					</ul>

					<div className="copyright-app">
						© {year} DOIS Vs CASHBACK
					</div>
				</div>

				<div className="container-content cj">
					<div className="container-component">
						{path === "index" ? (
							<div className="main-component">
								<div className="u-title">
									Meu código de indicação
								</div>
								<div className="clear _10"></div>

								<div className="component-flex">
									<div className="id-codigo">
										{user?.code}
									</div>
									<button
										className="btn-share"
										onClick={() => {
											setShowModal(!show);
										}}
									>
										COMPARTILHAR
									</button>
								</div>
								<ModalShare
									show={show}
									_ref={ref}
									codigo={user?.code}
								/>
								<div className="clear _50"></div>

								<div className="sub-title">
									Saldo atual disponível
								</div>
								<div className="clear _10"></div>
								<div className="component-flex">
									<div className="id-saldo">
										R$ {formatedvalue}
									</div>
								</div>

								<div className="clear _30"></div>
								{loading === false && cash_value === "" ? (
									<div>
										<div className="showForm">
											Defina o valor de cashback que você
											oferecerá para seus clientes (entre
											2 e 30%).
											<div className="clear _20"></div>
											<div className="slider">
												<input
													type="range"
													id="min"
													className="min"
													min="0"
													max="30"
													step="0.5"
													defaultValue="2"
													onChange={(e) => {
														let value = parseFloat(
															e.target.value
														);
														setCashValue(value);
													}}
												/>

												<div className="_slider">
													<span className="track"></span>
													<span className="thumb left"></span>
												</div>

												<span className="range_min left">
													2.5%
												</span>
												<span className="range_max right">
													30%
												</span>

												<div className="clear _30"></div>
												<div className="aviso">
													Só será permitido alterar
													este valor posteriormente
													com o nosso suporte.
												</div>
												<div className="error_message">
													{msgErr}
												</div>
												<button
													className="btn-save"
													disabled={load}
													onClick={() => {
														setLoad(true);
														handleCashback();
													}}
												>
													Salvar
												</button>
											</div>
										</div>

										<div className="clear _20"></div>
									</div>
								) : (
									""
								)}

								<div className="sub-title">
									Cashback oferecido
								</div>
								<div className="clear _10"></div>
								<div className="component-flex">
									<div className="id-cashback">
										{cash_value !== "" ? cash_value : 0}%
									</div>
								</div>
							</div>
						) : (
							<Component />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppLogged;
