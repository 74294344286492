import { useContext, useEffect, useRef, useState } from "react";

import "./styles.css";

import { ReactComponent as Camera } from "../../assets/switch_camera.svg";

import { QrReader } from "react-qr-reader";

import { AuthContext } from "../../services/auth";

import api from "../../utils/api";

import { Base64 } from "js-base64";

import { formatCurrency, generateUniqID } from "../../utils/functions";

import { io } from "socket.io-client";

let socket;
let port = 1113;
let whost = "wss://doisvs.com.br:" + port;
let ws_token = "";

const AppQRCode = () => {
	const { user } = useContext(AuthContext);

	useEffect(() => {
		try {
			let options = {
				query: { ws_token },
				transports: ["websocket", "polling"],
				reconnect: true,
				rejectUnauthorized: false,
				secure: false,
			};

			socket = io(whost, options);

			socket.on("connect", () => {
				console.log("user web connected!");
			});

			socket.on("connect_error", (err) => {
				console.log(err);
			});
		} catch (err) {
			console.log(err);
		}
	}, []);

	const token = localStorage.getItem("token");

	const ref = useRef(null);
	const [show, setShowModal] = useState(false);

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setShowModal(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	const [_orderID, _setOrderID] = useState("");
	const [orderID, setOrderID] = useState("");
	const [clientID, setClientID] = useState("");
	const [value, setValue] = useState("");

	// const [getresult, setData] = useState("");
	const [watching, setWatching] = useState(false);
	const [typeCam, setCam] = useState(true);

	const [msgError, setMsgErr] = useState("");
	const [msgErrorCallback, setMsgErrCallback] = useState("");
	const [load, setLoad] = useState(false);

	const handleGenerateCode = async (e) => {
		e.preventDefault();
		let code = await generateUniqID(0);
		await _setOrderID(code);
		await setOrderID(code);
	};

	const handleQRCode = async () => {
		if (orderID === "" || clientID === "") {
			setMsgErr("Todos os campos devem ser preenchidos!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		} else if (value === "") {
			setMsgErr("Digite um valor válido!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		}

		let res = await api
			.post(
				"register/qrcode",
				{
					orderID: orderID,
					clientID: clientID,
					partnerID: user?.code,
					value: value,
				},
				{
					headers: { token: token },
				}
			)
			.catch((err) => {
				setMsgErr("Não foi possível completar a requisição!");
				setLoad(false);
			});

		let status = res?.data?.status;
		let message = res?.data?.message;

		if (status === 1) {
			setOrderID("");
			setClientID("");
			setValue("");

			setShowModal(!show);
			setLoad(false);
		} else {
			setMsgErr(message);
		}

		setShowModal(!show);
		setLoad(false);

		setTimeout(() => {
			setMsgErr("");
		}, 4000);
		setLoad(false);
	};

	const onFind = (result, error) => {
		if (!!result) {
			if (result.text !== undefined) {
				let result_qrcode = result?.text;
				let partnerID = user?.code;
				let pedidoID = _orderID;

				var base_decode = Base64.decode(result_qrcode);
				let splits = base_decode.split(":");

				setWatching(!watching);

				if (splits[0] === pedidoID && splits[1] === partnerID) {
					// setData(result?.text);

					if (socket) {
						socket.emit("my_cashback", result?.text);
					}

					setShowModal(!show);
				} else {
					setMsgErrCallback(
						"Falha na leitura. Dados não correspondem!"
					);
				}
			}
		}

		if (!!error) {
			if (error.constraint !== undefined) {
				setMsgErrCallback(
					"Error: " + error.constraint + " (Câmera não detectada!)"
				);
			}
		}
	};

	return (
		<>
			<div className="main-component">
				<div className="u-title">Gerar leitura de QRCode</div>
				<div className="form-app">
					<div className="input-orderID">
						<div className="input-flex">
							<input
								type="text"
								name="code"
								autoComplete="off"
								placeholder="Código da compra"
								readOnly={true}
								value={orderID}
								onChange={(e) => {
									let v = e.target.value;
									setOrderID(v);
								}}
							/>

							<div className="btn-gerar">
								<a
									href="#gerar"
									onClick={(e) => {
										handleGenerateCode(e);
									}}
								>
									Gerar
								</a>
							</div>
						</div>
						<div></div>
					</div>
					<div className="clear _10"></div>

					<input
						type="text"
						name="clientID"
						autoComplete="off"
						placeholder="ID do cliente"
						value={clientID}
						onChange={(e) => {
							let v = e.target.value;
							setClientID(v);
						}}
					/>
					<div className="clear _10"></div>

					<input
						type="text"
						name="price"
						autoComplete="off"
						placeholder="Valor da compra"
						value={value}
						onChange={(e) => {
							let v = e.target.value;
							let val = formatCurrency(v);
							setValue(val);
						}}
					/>
					<div className="clear _10"></div>

					<div
						className={
							msgError !== ""
								? "errorBox-components"
								: "errorBoxHide"
						}
					>
						{msgError}
					</div>
					<button
						disabled={load}
						onClick={() => {
							setLoad(true);
							handleQRCode();
						}}
					>
						LER QRCODE
					</button>
				</div>

				<div className={!show ? "modal-qr-hide" : "modal-qr"}>
					<div ref={ref} className="center-modal">
						<div>
							Código de compra: <b>{_orderID}</b>
						</div>
						<div className="clear _10"></div>
						<div className="qr-area">
							{watching ? (
								<QrReader
									constraints={
										typeCam
											? {
													facingMode: {
														exact: "environment",
													},
											  }
											: {}
									}
									scanDelay={200}
									onResult={onFind}
									style={{ width: "100%" }}
									legacyMode
								/>
							) : (
								<div></div>
							)}

							<div
								className={
									watching ? "box-btn" : "box-btn-hide"
								}
							>
								<button
									className="btn-change-cam"
									onClick={() => {
										setCam(!typeCam);
										if (watching === true) {
											setWatching(false);
											setMsgErrCallback("");

											setTimeout(() => {
												setWatching(true);
											}, 100);
										}
									}}
								>
									<Camera
										className="ico-cam"
										fill="#FFFFFF"
										height={33}
										onClick={() => {}}
									/>
								</button>
							</div>
						</div>

						{/*<p>{result}</p>*/}

						{msgErrorCallback === "" ? (
							<div className="clear _10"></div>
						) : (
							""
						)}

						<div
							className={
								msgErrorCallback === "" ? "error-hide" : "error"
							}
						>
							{msgErrorCallback}
						</div>

						<button
							onClick={() => {
								setWatching(!watching);
								setMsgErrCallback("");
							}}
						>
							{!watching
								? "Ler/escanear QRCode"
								: "Fechar câmera"}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default AppQRCode;
