import { useEffect, useState } from "react";

import api from "../../utils/api";

const AppReportsExtract = () => {
	const [list, setList] = useState([]);

	const token = localStorage.getItem("token");

	useEffect(() => {
		const getTransactions = async () => {
			const res = await api
				.get("transactions", {
					headers: { token: token },
				})
				.catch((error) => {
					console.log(error?.response);
				});

			if (res) {
				let transactions = res?.data?.transactions;
				setList(transactions);
			}
		};
		getTransactions();
	}, [token]);

	const renderSwitch = (param) => {
		switch (param) {
			case "buy":
				return "Compra";
			case "buy2":
				return "Venda";
			case "cash":
				return "Depósito";
			case "withdraw":
				return "Saque";
			case "invoice":
				return "Fatura";
			default:
				return "foo";
		}
	};

	return (
		<>
			<div className="table-responsive">
				<table
					className="table table-bordered"
					id="datatableTransaction"
				>
					<thead>
						<tr>
							<th>Data</th>
							<th>Tipo</th>
							<th>Usuário</th>
							<th>Valor</th>
							<th>Cashback</th>
							<th>Total distribuido</th>
							<th>Status</th>
							<th>Ações</th>
						</tr>
					</thead>
					<tbody id="tbodyTransaction">
						{list.map((tr) => (
							<tr key={tr.code}>
								<th>{tr.date_fmt}</th>
								<th>{renderSwitch(tr.type)}</th>
								<th>{tr.username}</th>
								<th>R${tr.fmt_value}</th>
								<th>{tr.cashback}%</th>
								<th>R${tr.total}</th>
								<th>{tr.status_detail}</th>
								<th>-</th>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default AppReportsExtract;
