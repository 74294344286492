import { useEffect, useState } from "react";

import api from "../../utils/api";

const AppConfigBank = () => {
	const [list, setList] = useState([]);
	const [name, setName] = useState("");
	const [bank, setBank] = useState("");
	const [agency, setAgency] = useState("");
	const [a_digit, setADigit] = useState("");
	const [account, setAccount] = useState("");
	const [digit, setDigit] = useState("");
	const [type_account, setType] = useState("");
	const [msgError, setMsgErr] = useState("");
	const [load, setLoad] = useState(false);

	const token = localStorage.getItem("token");

	useEffect(() => {
		const fillBanks = async () => {
			const res = await api.get("banks").catch((error) => {
				console.log(error?.response);
			});
			setList(res?.data);
		};

		fillBanks();
	}, []);

	useEffect(() => {
		const partnerData = async () => {
			const res = await api
				.get("partner/data", {
					headers: { token: token },
				})
				.catch((error) => {
					console.log(error?.response);
				});

			if (res) {
				let data_bk = res?.data?.bank_account;

				setName(data_bk?.client_name);
				setBank(data_bk?.bank_code);
				setAgency(data_bk?.bank_agency);
				setADigit(data_bk?.a_digit);
				setAccount(data_bk?.account);
				setDigit(data_bk?.digit);
				setType(data_bk?.type_account);
			}
		};
		partnerData();
	}, [token]);

	const handleBank = async () => {
		let type_account = document.querySelector("[name=tipo_conta]").value;

		if (
			name === "" ||
			bank === "" ||
			agency === "" ||
			a_digit === "" ||
			account === "" ||
			digit === "" ||
			type_account === "null"
		) {
			setMsgErr("Preencha os campos obrigatórios!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		}

		const res = await api
			.post(
				"partner/bank/update",
				{
					client_name: name,
					bank: bank,
					agency: agency,
					a_digit: a_digit,
					account: account,
					digit: digit,
					type_account: type_account,
				},
				{
					headers: { token: token },
				}
			)
			.catch((err) => {
				console.log(err);
				setLoad(false);
			});

		let status = res?.data?.status;
		let message = res.data.message;

		if (status === 1) {
			setMsgErr(message);
		} else {
			setBank(bank);
			setAgency(agency);
			setADigit(a_digit);
			setAccount(account);
			setDigit(digit);

			setMsgErr(message);
		}

		setTimeout(() => {
			setMsgErr("");
		}, 3000);

		setLoad(false);
	};

	// let bank_code =
	// 	dataBank?.bank_code !== undefined ? dataBank?.bank_code : "null";

	// let type_account =
	// 	dataBank?.type_account !== undefined ? dataBank?.type_account : "null";

	return (
		<>
			<div className="box-dados-aviso">
				A conta bancária deve estar vinculada ao CPF/CNPJ do titular
				desta conta.
				<br />
				*Não nos responsabilizamos por dados divergentes.
			</div>

			<div className="form-dados">
				<div className="flex-box">
					<input
						name="name"
						type="text"
						className="clientName"
						placeholder="Nome completo"
						autoComplete="off"
						maxLength="255"
						defaultValue={name}
						onChange={(e) => {
							setName(e.target.value);
						}}
					/>
				</div>

				<div className="clear _10"></div>

				<select
					name="codigo_banco"
					onChange={(e) => {
						let v = e.target.value;
						setBank(v);
					}}
					value={bank}
				>
					<option value="null">Selecione um banco</option>
					{list.map((b) => (
						<option key={b.code} value={b.code}>
							{b.code} - {b.name}
						</option>
					))}
				</select>
				<div className="clear _10"></div>

				<span className="aviso-agencia">
					Para agência/conta sem dígito, preencha com 0.
				</span>
				<div className="clear"></div>

				<div className="flex-box">
					<input
						name="agency"
						type="text"
						className="bank"
						placeholder="Agência"
						autoComplete="off"
						maxLength="6"
						defaultValue={agency}
						onChange={(e) => {
							setAgency(e.target.value);
						}}
					/>

					<input
						name="ag_digito"
						type="text"
						className="digit"
						id="ag_digito"
						placeholder="Dígito"
						autoComplete="off"
						maxLength="2"
						defaultValue={a_digit}
						onChange={(e) => {
							setADigit(e.target.value);
						}}
					/>
				</div>
				<div className="clear _10"></div>

				<div className="flex-box">
					<input
						name="account"
						type="text"
						className="bank"
						placeholder="Conta"
						autoComplete="off"
						maxLength="15"
						value={account}
						onChange={(e) => {
							setAccount(e.target.value);
						}}
					/>

					<input
						name="digit"
						type="text"
						className="digit"
						placeholder="Dígito"
						autoComplete="off"
						maxLength="2"
						value={digit}
						onChange={(e) => {
							setDigit(e.target.value);
						}}
					/>
				</div>
				<div className="clear _10"></div>

				<select
					name="tipo_conta"
					id="tipo_conta"
					onChange={(e) => {
						let v = e.target.value;
						setType(v);
					}}
					value={type_account}
				>
					<option value="null">Selecione o tipo de conta</option>
					<option value="checking">Conta corrente</option>
					<option value="saving">Conta poupança</option>
				</select>
				<div className="clear _30"></div>

				<div
					className={
						msgError !== "" ? "errorBox-components" : "errorBoxHide"
					}
				>
					{msgError}
				</div>
				<button
					disabled={load}
					onClick={() => {
						setLoad(true);
						handleBank();
					}}
				>
					SALVAR
				</button>
			</div>
		</>
	);
};

export default AppConfigBank;
