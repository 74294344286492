import axios from "axios";

let BASE_URL = process.env.BASE_API || "https://api.doisvs.com.br/";

const api = axios.create({
  baseURL: BASE_URL,
  crossdomain: false,
  headers: {
    // "Access-Control-Allow-Origin": "*",
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  },
});

export default api;
