import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./styles.css";

import logo from "../../assets/logo.png";

const HeaderBar = ({ page }) => {
	// const base = process.env.PUBLIC_URL || "";
	let base = "";

	const [showMenu, setShowMenu] = useState(false);

	var link_google =
		"https://play.google.com/store/apps/details?id=com.doisvs.app";

	return (
		<>
			<header className="header">
				<div className="nav-container">
					<NavLink to={base + "/"} title="Página inicial">
						<img src={logo} alt="Logo Dois VS Cashback" />
					</NavLink>
					<span className="menu-container-header">
						<label
							htmlFor="box-menu"
							onClick={() => {
								setShowMenu(!showMenu);
							}}
						></label>
					</span>

					<span
						className={
							showMenu
								? "menu-responsivo-checked"
								: "menu-responsivo"
						}
					>
						<span className="icon-close-menu">
							<label
								htmlFor="box-menu"
								onClick={() => {
									setShowMenu(!showMenu);
								}}
							></label>
						</span>
						<div className="clear _30"></div>

						<ul className="style-menu-resp">
							<span className="item-menu-responsivo">
								<li
									className={
										page === "index" ? "selected" : ""
									}
								>
									<NavLink to={base + "/para-voce"}>
										Para você
										<span className="text-underline"></span>
									</NavLink>
								</li>
							</span>

							<span className="item-menu-responsivo">
								<li
									className={
										page === "estab" ? "selected" : ""
									}
								>
									<NavLink to={base + "/estabelecimentos"}>
										Estabelecimentos
										<span className="text-underline"></span>
									</NavLink>
								</li>
							</span>

							<span className="item-menu-responsivo">
								<li
									className={
										page === "sobre" ? "selected" : ""
									}
								>
									<NavLink to={base + "/sobre"}>
										Sobre nós
										<span className="text-underline"></span>
									</NavLink>
								</li>
							</span>

							<span className="item-menu-responsivo">
								<li
									className={
										page === "ajuda" || page === "funciona"
											? "selected"
											: ""
									}
								>
									<NavLink to={base + "/ajuda"}>
										Ajuda
										<span className="text-underline"></span>
									</NavLink>
								</li>
							</span>
						</ul>

						<div className="nav_menu-links-responsive">
							{page === "estab" ? (
								<ul>
									<li>
										<NavLink
											to={base + "/app"}
											className={
												page === "app" ? "selected" : ""
											}
										>
											Acessar conta
										</NavLink>
									</li>
								</ul>
							) : (
								""
							)}
						</div>
					</span>

					<div className="nav_menu-right">
						<ul className="style-menu-ul">
							<li className={page === "index" ? "selected" : ""}>
								<NavLink to={base + "/para-voce"}>
									Para você
									<span className="text-underline"></span>
								</NavLink>
							</li>
							<li className={page === "estab" ? "selected" : ""}>
								<NavLink to={base + "/estabelecimentos"}>
									Estabelecimentos
									<span className="text-underline"></span>
								</NavLink>
							</li>
							<li className={page === "sobre" ? "selected" : ""}>
								<NavLink to={base + "/sobre"}>
									Sobre nós
									<span className="text-underline"></span>
								</NavLink>
							</li>
							<li
								className={
									page === "ajuda" || page === "funciona"
										? "selected"
										: ""
								}
							>
								<NavLink to={base + "/ajuda"}>
									Ajuda
									<span className="text-underline"></span>
								</NavLink>
							</li>
						</ul>

						<div
							className={
								page === "estab"
									? "nav_menu-links-show"
									: "nav_menu-links"
							}
						>
							{page === "estab" ? (
								<ul>
									<li>
										<NavLink
											to={base + "/app"}
											className={
												page === "app" ? "selected" : ""
											}
										>
											Acessar conta
										</NavLink>
									</li>
								</ul>
							) : (
								<button
									onClick={() => {
										window.open(link_google, "_blank");
									}}
								>
									Baixe o app
								</button>
							)}
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default HeaderBar;
