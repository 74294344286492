import { useState } from "react";

import api from "../../utils/api";

const AppConfigPassword = ({ company, email }) => {
	const token = localStorage.getItem("token");

	const [password, setPassword] = useState("");
	const [n_password, setNewPassword] = useState("");
	const [re_password, setRePassword] = useState("");
	const [msgError, setMsgErr] = useState("");
	const [load, setLoad] = useState(false);

	const handlePassword = async () => {
		if (password === "" || n_password === "" || re_password === "") {
			setMsgErr("Preencha os campos obrigatórios!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		} else if (n_password !== re_password) {
			setMsgErr("Os campos da nova senha são diferentes!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		}

		const res = await api
			.post(
				"partner/password/update",
				{
					password: password,
					new_password: n_password,
					retype_password: re_password,
				},
				{
					headers: { token: token },
				}
			)
			.catch((err) => {
				console.log(err);
				setLoad(false);
			});

		let status = res?.data?.status;
		let message = res.data.message;

		if (status === 1) {
			setPassword("");
			setNewPassword("");
			setRePassword("");

			setMsgErr(message);
		} else {
			setPassword(password);
			setNewPassword(n_password);
			setRePassword(re_password);
			setMsgErr(message);
		}

		setTimeout(() => {
			setMsgErr("");
		}, 3000);

		setLoad(false);
	};

	return (
		<>
			<div className="clear _5"></div>
			<div className="form-app zero-pad-top">
				<div className="flex-box">
					<input
						name="password"
						type="password"
						placeholder="Senha atual"
						autoComplete="off"
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
					/>
				</div>
				<div className="clear _10"></div>

				<div className="flex-box">
					<input
						name="n_password"
						type="password"
						placeholder="Nova senha"
						autoComplete="off"
						value={n_password}
						onChange={(e) => {
							setNewPassword(e.target.value);
						}}
					/>

					<input
						name="re_password"
						type="password"
						placeholder="Repetir senha"
						autoComplete="off"
						value={re_password}
						onChange={(e) => {
							setRePassword(e.target.value);
						}}
					/>
				</div>
				<div className="clear _30"></div>

				<div
					className={
						msgError !== "" ? "errorBox-components" : "errorBoxHide"
					}
				>
					{msgError}
				</div>
				<button
					disabled={load}
					onClick={() => {
						setLoad(true);
						handlePassword();
					}}
				>
					SALVAR
				</button>
			</div>
		</>
	);
};

export default AppConfigPassword;
