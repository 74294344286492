import { NavLink, useParams } from "react-router-dom";

import "./styles.css";

import AppConfigAccount from "../../components/AppConfigAccount";
import AppConfigAddress from "../../components/AppConfigAddress";
import AppConfigUpdateAddress from "../../components/AppConfigUpdateAddress";
import AppConfigPassword from "../../components/AppConfigPassword";
import AppConfigPayment from "../../components/AppConfigPayment";
import AppConfigInvoice from "../../components/AppConfigInvoice";
import AppConfigBank from "../../components/AppConfigBank";

const AppConfig = () => {
	const params = useParams();
	const base = process.env.PUBLIC_APP_URL || "";
	let _base = base + "/app/configuracoes";

	let subpath =
		params.subpath !== undefined && params.subpath !== ""
			? params?.subpath
			: "";

	const VoidLayout = () => {
		return <></>;
	};

	let Component = VoidLayout;
	let page = "";

	switch (subpath) {
		case "conta":
			Component = AppConfigAccount;
			page = "Conta";
			break;

		case "senha":
			Component = AppConfigPassword;
			page = "Alterar Senha";
			break;

		case "endereco":
			Component = AppConfigAddress;
			page = "Endereço";
			break;

		case "editendereco":
			Component = AppConfigUpdateAddress;
			page = "Atualizar Endereço";
			break;

		case "faturas":
			Component = AppConfigInvoice;
			page = "Faturas";
			break;

		case "pagamento":
			Component = AppConfigPayment;
			page = "Pagamento";
			break;

		case "dados-bancarios":
			Component = AppConfigBank;
			page = "Dados bancários";
			break;

		default:
			Component = VoidLayout;
			break;
	}

	const Title = ({ page }) => {
		return (
			<div>
				{page === "" ? (
					<div className="u-title">Configurações</div>
				) : (
					<div className="u-title">
						<span>
							<NavLink
								className="item-link-title"
								to={_base + ""}
							>
								Configurações
							</NavLink>
						</span>
						&#160; › <span className="subpage">{page}</span>
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			<div className="main-component">
				<Title page={page} />
				<div className="clear _10"></div>
				<div className="submenu-component multiple-links">
					<NavLink
						className="item-link-component"
						to={_base + "/conta"}
					>
						CONFIGURAÇÕES DA CONTA
					</NavLink>

					<NavLink
						className="item-link-component"
						to={_base + "/senha"}
					>
						SENHA
					</NavLink>

					<NavLink
						className="item-link-component"
						to={_base + "/endereco"}
					>
						ENDEREÇO
					</NavLink>

					<NavLink
						className="item-link-component"
						to={_base + "/faturas"}
					>
						FATURAS
					</NavLink>

					<NavLink
						className="item-link-component"
						to={_base + "/pagamento"}
					>
						PAGAMENTO
					</NavLink>
				</div>
				<div className="clear _30"></div>

				<Component />
			</div>
		</>
	);
};

export default AppConfig;
