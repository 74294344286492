import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import "./styles.css";

import { ReactComponent as Logo } from "../../assets/logo_login.svg";

import { AuthContext } from "../../services/auth";

import api from "../../utils/api";

import { formatDoc, formatCNPJ } from "../../utils/functions";

const SignUp = () => {
	let base = "";
	const [type, setType] = useState("cnpj");
	const [doc, setDoc] = useState("");
	const [ml, setMaxLength] = useState(18);
	const [company, setCompany] = useState("");
	const [category, setCategory] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [re_password, setRePassword] = useState("");
	const [refID, setRefID] = useState("");
	const [msgNominee, setMsgNominee] = useState("");

	const [list, setList] = useState([]);
	const [msgError, setMsgErr] = useState("");
	const [load, setLoad] = useState(false);

	useEffect(() => {
		const fillCategories = async () => {
			const res = await api.get("categories");
			setList(res.data);
		};
		fillCategories();
	}, []);

	const { signed, setUser } = useContext(AuthContext);

	const signin = async () => {
		if (
			doc === "" ||
			company === "" ||
			email === "" ||
			password === "" ||
			re_password === ""
		) {
			setMsgErr("Preencha os campos obrigatórios!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		} else if (category === "" || category === "null") {
			setMsgErr("Selecione uma categoria de estabelecimento!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		}

		let res = await api
			.post("register/partner", {
				type_doc: type,
				doc: doc,
				company: company,
				type: category,
				email: email,
				password: password,
				re_password: re_password,
				indicationCode: refID,
			})
			.catch((err) => {
				setMsgErr("Não foi possível completar a requisição!");
				setLoad(false);
			});

		let status = res?.data?.status;
		let message = res?.data?.message;
		let token = res?.data?.token;
		let res_user = res?.data?.user !== undefined ? res?.data?.user : null;

		if (status === 0) {
			setMsgErr(message);
		} else if (status === 400) {
			setMsgErr("Não foi possível completar a requisição!");
			setLoad(false);
		} else {
			setUser(res_user);

			localStorage.setItem("user", res_user);
			localStorage.setItem("token", token);
		}
		setLoad(false);

		setTimeout(() => {
			setMsgErr("");
		}, 4000);
	};

	if (signed) {
		setTimeout(() => {
			window.location = "./";
		}, 100);
		return null;
	}

	const handleIndicationCode = async (_code) => {
		setRefID(_code);

		if (_code !== "" && _code.length >= 4) {
			const res = await api
				.post("indication", {
					indicationCode: _code,
				})
				.catch((err) => {
					setMsgErr("Não foi possível completar a requisição!");
				});

			let status = res?.data?.status;
			let message = res?.data?.message;

			if (status === 0) {
				setMsgNominee("");
			} else {
				setMsgNominee(message);
			}
		}
	};

	return (
		<>
			<div className="content-login">
				<div className="box-login">
					<div className="logo-box-login">
						<Logo height={100} />
					</div>
					<div className="title-login">Faça seu cadastro</div>
					<div className="box-doc">
						<select
							name="doc"
							className="select-doc"
							defaultValue={type}
							onChange={(e) => {
								let selected = e.target.value;

								if (selected === "cnpj") {
									setMaxLength(18);
									setType("cnpj");
								} else {
									setMaxLength(14);
									setType("cpf");
								}
							}}
						>
							<option value="cnpj">CNPJ</option>
							<option value="cpf">CPF</option>
						</select>
						<input
							type="text"
							name="cpf_cnpj"
							inputMode="numeric"
							autoComplete="off"
							placeholder="Doc."
							maxLength={ml}
							value={doc}
							onChange={(e) => {
								let v = e.target.value;
								let fmtDoc;
								fmtDoc =
									ml === 18 ? formatCNPJ(v) : formatDoc(v);

								setDoc(fmtDoc);
							}}
						/>
					</div>

					<input
						type="text"
						name="company"
						inputMode="text"
						autoComplete="off"
						placeholder="Empresa"
						maxLength={64}
						value={company}
						onChange={(e) => {
							let v = e.target.value;
							setCompany(v);
						}}
					/>

					<select
						name="category"
						className={
							category === "" || category === "null"
								? "select-category"
								: "select-category-selected"
						}
						onChange={(e) => {
							let v = e.target.value;
							setCategory(v);
						}}
						defaultValue="null"
					>
						<option value="null">Segmento</option>
						{list.map((cat) => (
							<option key={cat.code} value={cat.slug}>
								{cat.title}
							</option>
						))}
					</select>

					<input
						type="text"
						name="login"
						inputMode="email"
						autoComplete="off"
						placeholder="Email"
						value={email}
						onChange={(e) => {
							let v = e.target.value;
							setEmail(v);
						}}
					/>

					<input
						type="password"
						name="_pass"
						inputMode="text"
						autoComplete="off"
						placeholder="Senha"
						value={password}
						onChange={(e) => {
							let v = e.target.value;
							setPassword(v);
						}}
					/>

					<input
						type="password"
						name="re_pass"
						inputMode="text"
						autoComplete="off"
						placeholder="Repetir senha"
						value={re_password}
						onChange={(e) => {
							let v = e.target.value;
							setRePassword(v);
						}}
					/>

					<div className="clear _20"></div>

					<div
						className={
							msgNominee === "" ? "hideNominee" : "textNominee"
						}
					>
						<div>
							<span className="boldNominee">Usuário: </span>
							{msgNominee}
						</div>
					</div>
					<div className="clear _10"></div>

					<input
						type="text"
						name="refID"
						inputMode="numeric"
						autoComplete="off"
						placeholder="Código de indicação"
						maxLength={12}
						value={refID}
						onChange={(e) => {
							let code = e.target.value;
							handleIndicationCode(code);
						}}
					/>

					<div className="terms-read">
						<div>
							Antes de continuar, certifique-se de ter lido nossos
						</div>
						<a
							href={base + "/termos-parceiros"}
							rel="noreferrer"
							target="_blank"
						>
							Termos de uso de parceiros
						</a>
					</div>
					<div className="clear _10"></div>

					<div
						className={
							msgError !== "" ? "errorBox" : "errorBoxHide"
						}
					>
						{msgError}
					</div>

					<button
						disabled={load}
						onClick={() => {
							setLoad(true);
							signin();
						}}
					>
						CONTINUAR
					</button>
					<div className="clear _20"></div>

					<div className="link-signup">
						Já tem uma conta?
						<NavLink to={base + "/app"}>Faça login</NavLink>
					</div>
				</div>
				<div className="box-icon"></div>
			</div>
		</>
	);
};

export default SignUp;
