import React, { useEffect } from "react";

import "./styles.css";

import HeaderBar from "../HeaderBar";

import Footer from "../Footer";

import banner from "../../assets/banner_indicados.png";

const Work = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<HeaderBar page="funciona" />
			<div className="content-work">
				<div className="title-work">Como funciona?</div>
				<div className="item-banner">
					<img src={banner} width="auto" alt="Banner de indicados" />
				</div>
				<div className="text-content">
					Suponha que a Ana gerou um cashback de R$10,00 com sua
					propria compra, 1 décimo (R$1,00) retorna para ela mesma, 3
					décimos (R$3,00) são creditados para o João, 2
					décimos(R$2,00) são creditados para você, 4 décimos (R$4,00)
					pertencem à Dois VS Cashback.
					<br />
					<br />
					Caso a compra seja feita pelo João, com o mesmo valor de
					cashback, ele recebe 1 décimo (R$1,00) referente a sua
					própria compra, e você recebe 3 décimos (R$3,00).
				</div>
			</div>

			<Footer />
		</>
	);
};

export default Work;
