import React, { useEffect } from "react";

import "./styles.css";

import HeaderBar from "../HeaderBar";

import Footer from "../Footer";

const Indicate = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<HeaderBar page="indique" />
			<div className="content-indicate">
				<div className="box-indicate">
					<div className="content-text">
						Indique seus amigos, monte sua rede de relacionamentos e
						receba bônus em dinheiro.
						<div className="content-button">
							<button
								onClick={() => {
									window.location = "./ajuda/como-funciona";
								}}
							>
								VEJA COMO FUNCIONA
							</button>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default Indicate;
