import { useMemo, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import "./styles.css";

import api from "../../utils/api";

const AppIndicate = () => {
	const params = useParams();
	const base = process.env.PUBLIC_APP_URL || "";
	let _base = base + "/app/indicados";

	const [loading, setLoading] = useState(false);
	const [list, setNominees] = useState([]);
	const [userNominee, setUserNominee] = useState("");

	const token = localStorage.getItem("token");

	let subpath =
		params.subpath !== undefined && params.subpath !== ""
			? params?.subpath
			: "";

	useMemo(() => {
		setLoading(true);
		const getPartnerData = async () => {
			const res = await api
				.get("sub/nominees", {
					params: { id: subpath, type: "partners" },
					headers: { token: token },
				})
				.catch((error) => {
					console.log("Response:", error?.response?.data);
					setLoading(false);
				});

			if (res) {
				setLoading(false);

				setUserNominee(res?.data?.name);
				if (res?.data?.nominees !== undefined) {
					setNominees(res?.data?.nominees);
				}
			} else {
				setLoading(false);
			}
		};

		getPartnerData();
	}, [token, subpath]);

	if (loading) {
		return <></>;
	}

	return (
		<>
			<div className="main-component">
				<div className="u-title">
					<span>
						<NavLink className="item-link-title" to={_base + ""}>
							Meus indicados
						</NavLink>
					</span>
					&#160; ›
					<span className="subpage">Indicados de: {userNominee}</span>
				</div>

				<div className="form-app">
					{list.length <= 0 ? (
						<div className="no-indicate">
							Este usuário não possui nenhum indicado no momento.
						</div>
					) : (
						<div className="title-indicate">
							Indicados do 3º nível
						</div>
					)}

					{list.map((nom) => (
						<div key={nom.code} className="box-item-indicate">
							<div className="item-static-indicate">
								<div className="item-name-indicate">
									{nom.name}
								</div>
								<div>{nom.email}</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default AppIndicate;
