import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./index.css";
import AppIndex from "./AppIndex";
import Partners from "./pages/Partners";
import About from "./pages/About";
import Help from "./pages/Help";
import HelpApp from "./pages/HelpApp";
import App from "./pages/App";
import AppLogged from "./pages/AppLogged";

import Recovery from "./pages/Recovery";
import SignUp from "./pages/SignUp";
import Work from "./pages/Work";
import Indicate from "./pages/Indicate";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import TermsPartners from "./pages/TermsPartners";
import PageCategory from "./pages/PageCategory";
import PagePartner from "./pages/PagePartner";
import { AuthProvider } from "./services/auth";

const basename = process.env.PUBLIC_URL || "";

ReactDOM.render(
  <>
    <BrowserRouter basename={basename}>
      <Routes>
        <Route path="/" element={<AppIndex />} />
        <Route path="/para-voce" element={<AppIndex />} />
        <Route path="/estabelecimentos" element={<Partners />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/ajuda" element={<Help />} />
        <Route path="/ajuda/app" element={<HelpApp />} />
        <Route path="/ajuda/como-funciona" element={<Work />} />
        <Route path="/indique" element={<Indicate />} />
        <Route path="/privacidade" element={<Privacy />} />
        <Route path="/termos" element={<Terms />} />
        <Route path="/termos-parceiros" element={<TermsPartners />} />

        <Route path="/page/category" element={<PageCategory />} />
        <Route path="/page/category/:id" element={<PageCategory />} />
        <Route path="/page/partner" element={<PagePartner />} />
        <Route path="/page/partner/:id" element={<PagePartner />} />

        <Route
          path="/app"
          element={
            <AuthProvider>
              <App />
            </AuthProvider>
          }
        />
        <Route
          path="/app/:path"
          element={
            <AuthProvider>
              <AppLogged />
            </AuthProvider>
          }
        />
        <Route
          path="/app/:path/:subpath"
          element={
            <AuthProvider>
              <AppLogged />
            </AuthProvider>
          }
        />
        <Route
          path="/app/cadastro"
          element={
            <AuthProvider>
              <SignUp />
            </AuthProvider>
          }
        />

        <Route
          path="/app/recovery"
          element={
            <AuthProvider>
              <Recovery />
            </AuthProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  </>,
  document.getElementById("content")
);
