import React from "react";
import "./styles.css";

import HeaderBar from "../HeaderBar";

import Footer from "../Footer";

import ic_percent from "../../assets/icon_percent.png";
import ic_categories from "../../assets/icon_categories.png";
import ic_lorem from "../../assets/icon_lorem.png";

const Partners = () => {
	const go = () => {
		window.location = "./app/cadastro";
	};

	return (
		<>
			<HeaderBar page="estab" />
			<div className="box-partners">
				<div className="content-banner-partners">
					<div className="box-text-banner">
						Faça seu negócio lucrar, crie fidelidade com seus
						clientes.
						<div className="subtitle-banner-partners">
							Seja um parceiro Dois Vs
						</div>
						<div className="clear _10"></div>
						<button
							onClick={() => {
								go();
							}}
						>
							Quero fazer parte
						</button>
					</div>
				</div>
			</div>

			<div className="content-partners">
				<div className="box-flex-items">
					<div className="item-col-lt">
						<div className="icon-item-col">
							<img src={ic_percent} alt="Cashback" />
						</div>
						Receba cashback de seus clientes
						<div className="text-item">
							Indique seus clientes, compartilhe seu código e
							ganhe também nas compras dos mesmos.
						</div>
					</div>
					<div className="item-col-lt">
						<div className="icon-item-col">
							<img src={ic_categories} alt="Categorias" />
						</div>
						Diversas categorias de negócios
						<div className="text-item">
							Seja um grande comércio, ou um prestador de
							serviços, você pode ser um parceiro da Dois Vs.
						</div>
					</div>
					<div className="item-col-lt">
						<div className="icon-item-col">
							<img src={ic_lorem} alt="Lorem" />
						</div>
						Pagamentos automatizados
						<div className="text-item">
							Receba o dinheiro na sua conta automaticamente, ao
							final do dia (quando atingir o limite mínimo).
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default Partners;
