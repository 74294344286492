import React from "react";
import { useParams } from "react-router-dom";

import "./styles.css";

import ItemPartner from "../../components/ItemPartner";

import NotFound from "../../components/NotFound";

const PagePartner = () => {
	const params = useParams();
	let partID = params.id;

	return (
		<>
			<div className="partner-container">
				{partID === "" || partID === undefined ? (
					<NotFound />
				) : (
					<ItemPartner partnerID={partID} />
				)}
			</div>
		</>
	);
};

export default PagePartner;
