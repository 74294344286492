import React from "react";
import "./styles.css";

const ItemCategory = ({ categoryID, item }) => {
	let title = item[0] ? item[0].title : "";
	// let slug = item[0] ? item[0].slug : "";
	// let icon = item[0] ? item[0].icon : "";

	const partners = item[0] ? item[0].partners : [];

	let name = title;
	if (title !== undefined) {
		name = title.toLowerCase();
	}

	let base_avatar = "https://cdn.doisvs.com.br/partners/";

	return (
		<>
			<div className="item-category-container">
				<div className="banner-category">
					<div className="name-category">{title}</div>
				</div>
				<div className="list-partners-category">
					<div className="text-category">
						Estabelecimentos em <b>{name}</b>
					</div>

					<div className="container-partners-category">
						{partners.map((p) => (
							<div
								key={p.code}
								className="item-partner-category"
								style={{
									backgroundImage:
										"url(" + base_avatar + p.avatar + ")",
								}}
							>
								<b>{p.company}</b>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default ItemCategory;
