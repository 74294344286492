import React, { useEffect, useState } from "react";

import "./styles.css";

import api from "../../utils/api";

const ItemPartner = ({ partnerID }) => {
	const [item, setItem] = useState([]);
	const [DataisLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		const getPartner = async () => {
			const response = await api.get("get/partner", {
				params: { id: partnerID },
			});
			setItem(response.data);
			setDataLoaded(true);
		};
		getPartner();
	}, [partnerID]);

	if (!DataisLoaded)
		return (
			<div>
				<h1> .... </h1>
			</div>
		);

	let street = item[0].address.street;
	let num = item[0].address.num;
	let neighbour = item[0].address.neighbour;
	let city = item[0].address.city;
	let uf = item[0].address.uf;
	let cep = item[0].address.cep;

	let street_num = street !== "" && num !== "" ? `${street}, ${num}` : "";
	let neighbour_city =
		neighbour !== "" && city !== "" ? `${neighbour}, ${city} - ${uf}` : "";
	let _cep = cep !== "" ? `CEP.: ${cep}` : "";

	return (
		<>
			<div className="item-partner-container">
				<div className="container-align">
					<div
						className="img-avatar"
						style={{
							backgroundImage: "url(" + item[0].avatar + ")",
						}}
					></div>
					<div className="company-data">
						<div className="company-name">{item[0].company}</div>
						<div className="description">
							{item[0].settings.description}
						</div>
						<div className="phone">
							<b>Tel.:</b> {item[0].phone}
						</div>
						<div className="address">
							{street_num} {street_num !== "" ? <br /> : ""}
							{neighbour_city}
							{neighbour_city !== "" ? <br /> : ""}
							{_cep}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ItemPartner;
