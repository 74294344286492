import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./styles.css";

import ItemCategory from "../../components/ItemCategory";

import NotFound from "../../components/NotFound";

import api from "../../utils/api";

const PageCategory = () => {
	const params = useParams();
	let catID = params.id;
	const [item, setItem] = useState([]);
	const [DataisLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		const getCategory = async () => {
			const response = await api.get("get/category", {
				params: { id: catID },
			});
			setItem(response.data);
			setDataLoaded(true);
		};
		getCategory();
	}, [catID]);

	if (!DataisLoaded)
		return (
			<div>
				<h1> .... </h1>
			</div>
		);

	return (
		<>
			<div className="category-container">
				{catID === "" || catID === undefined ? (
					<NotFound />
				) : (
					<ItemCategory categoryID={catID} item={item} />
				)}
			</div>
		</>
	);
};

export default PageCategory;
