import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";

import "./styles.css";

import { formatCurrency } from "../../utils/functions";

import api from "../../utils/api";

const AppWithDraw = () => {
	const base = process.env.PUBLIC_APP_URL || "";
	let _baseconfig = base + "/app/configuracoes";

	const token = localStorage.getItem("token");

	const [userData, setData] = useState();
	const [value, setValue] = useState("");
	const [msgError, setMsgErr] = useState("");
	const [load, setLoad] = useState(false);

	useMemo(() => {
		const getPartnerData = async () => {
			const res = await api
				.get("partner/data", {
					headers: { token: token },
				})
				.catch((error) => {
					console.log(error?.response);
				});
			if (res) {
				setData(res?.data);
			}
		};
		getPartnerData();
	}, [token]);

	let cash_balance = userData?.balance;
	let formatedvalue =
		typeof cash_balance === "number"
			? cash_balance
					.toFixed(2)
					.replace(".", ",")
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
			: "--,--";

	const handleWithdraw = async () => {
		if (value === "") {
			setMsgErr("Digite um valor válido!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		}

		const res = await api
			.post(
				"partner/withdraw",
				{
					value: value,
				},
				{
					headers: { token: token },
				}
			)
			.catch((err) => {
				console.log(err);
				setLoad(false);
			});

		let status = res?.data?.status;
		let message = res.data.message;

		if (status === 1) {
			setValue("");

			setMsgErr(message);
		} else {
			setValue(value);

			setMsgErr(message);
		}

		setTimeout(() => {
			setMsgErr("");
		}, 3000);

		setLoad(false);
	};

	return (
		<>
			<div className="main-component">
				<div className="u-title">Resgate seu saldo</div>
				<div className="clear _10"></div>
				<div className="submenu-component">
					<NavLink
						className="item-link-component"
						to={_baseconfig + "/dados-bancarios"}
					>
						DADOS BANCÁRIOS
					</NavLink>
				</div>
				<div className="clear _10"></div>

				<div className="form-app">
					<div className="sub-title">Saldo atual disponível</div>
					<div className="clear _10"></div>
					<div className="component-flex">
						<div className="id-saldo">R$ {formatedvalue}</div>
					</div>
					<div className="clear _30"></div>
					<div className="box-dados-aviso">
						É necessário adicionar seus dados bancários e ter saldo
						suficiente para solicitar saque.
					</div>
					<div className="clear _30"></div>
					<input
						type="text"
						name="price"
						autoComplete="off"
						placeholder="Valor"
						value={value}
						onChange={(e) => {
							let v = e.target.value;
							let val = formatCurrency(v);
							setValue(val);
						}}
					/>
					<div className="clear _10"></div>

					<div
						className={
							msgError !== ""
								? "errorBox-components"
								: "errorBoxHide"
						}
					>
						{msgError}
					</div>
					<button
						disabled={load}
						onClick={() => {
							setLoad(true);
							handleWithdraw();
						}}
					>
						SOLICITAR SAQUE
					</button>
				</div>
			</div>
		</>
	);
};

export default AppWithDraw;
