import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";

import "./styles.css";

import api from "../../utils/api";

const AppIndicate = () => {
	const [loading, setLoading] = useState(false);
	const [list, setNominees] = useState([]);

	const token = localStorage.getItem("token");

	useMemo(() => {
		setLoading(true);
		const getPartnerData = async () => {
			const res = await api
				.get("partner/nominees", {
					headers: { token: token },
				})
				.catch((error) => {
					console.log("Response:", error?.response?.data);
					setLoading(false);
				});

			if (res) {
				setLoading(false);
				if (res?.data?.nominees !== undefined) {
					setNominees(res?.data?.nominees);
				}
			} else {
				setLoading(false);
			}
		};

		getPartnerData();
	}, [token]);

	if (loading) {
		return <></>;
	}

	return (
		<>
			<div className="main-component">
				<div className="u-title">Meus indicados</div>

				<div className="form-app">
					{list.length <= 0 ? (
						<div className="no-indicate">
							Você não possui nenhum indicado no momento.
						</div>
					) : (
						<div className="title-indicate">
							Seus indicados - 2º nível
						</div>
					)}

					{list.map((nom) => (
						<div key={nom.code} className="box-item-indicate">
							<NavLink
								className="item-link-indicate"
								to={"/app/indicado/" + nom.code}
							>
								<div className="item-name-indicate">
									{nom.name}
								</div>
								<div>{nom.email}</div>
							</NavLink>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default AppIndicate;
