import React from "react";
import { NavLink } from "react-router-dom";

import "./styles.css";

import icon_logo from "../../assets/logo.png";

import { ReactComponent as Twtt } from "../../assets/ico_twitter.svg";
import { ReactComponent as Insta } from "../../assets/ico_insta.svg";
import { ReactComponent as Face } from "../../assets/ico_face.svg";
import { ReactComponent as Google } from "../../assets/google_play.svg";

const Footer = () => {
	// const base = process.env.PUBLIC_URL || "";
	let base = "";

	var link_google =
		"https://play.google.com/store/apps/details?id=com.doisvs.app";

	const height = 23;

	return (
		<>
			<div className="content-footer">
				<div className="box-footer">
					<div className="column-box">
						<span className="footer-title">Sobre nós</span>

						<ul>
							<li>
								<NavLink to={base + "/sobre"}>
									Quem somos?
								</NavLink>
							</li>
							<li>
								<NavLink to={base + "/termos"}>
									Termos de uso
								</NavLink>
							</li>
							<li>
								<NavLink to={base + "/privacidade"}>
									Política de privacidade
								</NavLink>
							</li>
						</ul>
					</div>
					<div className="column-box">
						<span className="footer-title">Cashback</span>

						<ul>
							<li>
								<NavLink to={base + "/ajuda/como-funciona"}>
									Como funciona?
								</NavLink>
							</li>
							<li>
								<NavLink to={base + "/indique"}>
									Indique seus amigos
								</NavLink>
							</li>
							<li>
								<NavLink to={base + "/ajuda"}>
									Central de ajuda
								</NavLink>
							</li>
							<li className="empty"></li>
							<li>
								<a
									href={link_google}
									rel="noreferrer"
									target="_blank"
								>
									<Google height={40} />
								</a>
							</li>
						</ul>
					</div>
					<div className="column-box">
						<span className="footer-title">Contato</span>
						<div className="column-contact">
							<div className="phone">(31) 9 9567-8840</div>
							<div className="email">contato@doisvs.com.br</div>
							<div className="social">
								<Twtt
									className="ico ico-twtt"
									fill="#13C6C6"
									height={height}
									onClick={() => {
										window.open(
											"https://www.twitter.com/",
											"_blank"
										);
									}}
								/>
								<Insta
									className="ico ico-insta"
									fill="#7E0FB2"
									height={height}
									onClick={() => {
										window.open(
											"https://www.instagram.com/fenanx",
											"_blank"
										);
									}}
								/>
								<Face
									className="ico ico-face"
									fill="#1040CB"
									height={height}
									onClick={() => {
										window.open(
											"https://www.facebook.com/",
											"_blank"
										);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom">
					<img src={icon_logo} alt="Dois VS cashback" />
					<div className="copyright">
						<b>DOIS VS CASH BACK LTDA</b> &#160; — &#160; CNPJ:
						44.661.277/0001-30 &#160; - &#160; Rua Carandaí, 149 -
						Sala 01 - Bairro Chácara - Betim, MG
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
