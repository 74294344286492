import React from "react";
import "./App.css";

import HeaderBar from "./pages/HeaderBar";

import banner from "./assets/banner.png";

import Footer from "./pages/Footer";

import online_transactions from "./assets/online_transactions.svg";
import currency from "./assets/currency.svg";
import p2p from "./assets/money_p2p.svg";

const AppIndex = () => {
  return (
    <div className="main">
      <HeaderBar page="index" />
      <div className="content-box">
        <div className="banner-index">
          <img src={banner} alt="Banner Dois VS Cashback" />
        </div>
        <div className="main-content">
          <div className="section-box-items">
            <div className="flex-section-content cash">
              <div className="item-cash unset" title="">
                <img src={online_transactions} alt="Online transactions" />
                <div className="cash-name">
                  Compre nos estabelecimentos de nossos parceiros
                </div>
                <div className="cash-description">
                  Faça suas compras nos estabelecimentos parceiros e solicite o
                  seu cashback.
                </div>
              </div>

              <div className="item-cash unset" title="">
                <img src={currency} alt="Currency" />
                <div className="cash-name">
                  Receba cashback de suas compras e das compras dos seus
                  indicados
                </div>
                <div className="cash-description">
                  Montando sua rede, você pode gerar uma renda mensal acima de
                  R$2.000,00.
                </div>
              </div>
            </div>
          </div>

          <div className="section-box-share">
            <div className="content-text-section-share">
              Aumente seus ganhos estimulando o consumo de seus indicados
              diretos e indiretos nos parceiros comerciais da Dois Vs Cashback.
            </div>
            <img src={p2p} alt="Business P2P" />
          </div>
          <div className="clear _50"></div>
          <div className="section-box-before">
            <div className="section-text-content">
              <div className="_text-content">
                Múltiplas vantagens para você na hora de comprar.
              </div>
              <div className="subtext-content">
                Com a Dois Vs, você não tem só mais um sistema de cashback, mas
                toda uma rede onde poderá dividir com seus amigos, descontos
                exclusivos, e ganhos sobre as compras dos mesmos.
              </div>
            </div>
          </div>
          <div className="clear _50"></div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AppIndex;
