import { useEffect, useState } from "react";

import api from "../../utils/api";

const AppConfigInvoice = () => {
	const [list, setList] = useState([]);

	const token = localStorage.getItem("token");

	useEffect(() => {
		const getTransactions = async () => {
			const res = await api
				.get("invoices", {
					headers: { token: token },
				})
				.catch((error) => {
					console.log(error?.response);
				});

			if (res) {
				let invoices = res?.data?.invoices;
				setList(invoices);
			}
		};
		getTransactions();
	}, [token]);

	return (
		<>
			<div className="table-responsive">
				<table className="table table-bordered" id="dtRedeem">
					<thead>
						<tr>
							<th>Código</th>
							<th>Data</th>
							<th>Link fatura</th>
							<th>Valor</th>
							<th>Status</th>
							<th>Ações</th>
						</tr>
					</thead>
					<tbody id="tbodyRedeem">
						{/*<tr>
							<td colSpan="12" className="text-align no-invoice">
								Nenhuma fatura gerada.
							</td>
						</tr>*/}

						{list.map((inv) => (
							<tr key={inv.code}>
								<th>{inv.code}</th>
								<th>{inv.date_fmt}</th>
								<th>
									<a
										className="link_invoice"
										href={inv.link_boleto}
										rel="noreferrer"
										target="_blank"
									>
										Visualizar fatura
									</a>
								</th>
								<th>R${inv.fmt_value}</th>
								<th>{inv.payment_status}</th>
								<th></th>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default AppConfigInvoice;
