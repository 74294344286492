import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import api from "../../utils/api";

const AppConfigAddress = () => {
	const base = process.env.PUBLIC_APP_URL || "";
	let _base = base + "/app/configuracoes";

	const token = localStorage.getItem("token");

	const [address, setAddress] = useState("");
	const [cep, setCEP] = useState("");
	const [street, setStreet] = useState("");
	const [num, setNum] = useState("");
	const [neighbour, setNeighbour] = useState("");
	const [city, setCity] = useState("");
	const [uf, setUF] = useState("");

	useEffect(() => {
		const getPartnerData = async () => {
			const res = await api
				.get("partner/data", {
					headers: { token: token },
				})
				.catch((error) => {
					console.log(error?.response);
				});

			if (res) {
				let address = res?.data?.address ? res?.data?.address : {};

				setCEP(address?.cep);
				setStreet(address?.street);
				setNum(address?.num);
				setNeighbour(address?.neighbour);
				setCity(address?.city);
				setUF(address?.uf);

				setAddress(address);
			}
		};

		getPartnerData();
	}, [token]);

	return (
		<>
			<div className="clear"></div>
			<div className="form-app zero-pad-top">
				<div className="u-title">Dados de endereço</div>
				<div className="clear _20"></div>
				{Object.keys(address).length === 0 ||
				address.cep === "" ||
				address.street === "" ? (
					<div>Endereço não cadastrado.</div>
				) : (
					<div>
						CEP: {cep}
						<div className="clear _10"></div>
						<div className="box-flex-address">
							{street}, {num}
							<br />
							{neighbour} - {city}, {uf}
						</div>
					</div>
				)}

				<div className="clear _30"></div>
				<NavLink to={_base + "/editendereco"} className="btn-edit">
					Atualizar endereço
				</NavLink>
			</div>
		</>
	);
};

export default AppConfigAddress;
