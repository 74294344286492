import "./styles.css";

const ModalShare = ({ show, _ref, codigo }) => {
	let url = "https://play.google.com/store/apps/details?id=com.doisvs.app";
	let text = `Baixe o app e cadastre-se na Dois Vs Cashback utilizando o meu código ${codigo}.%0a${url}`;
	let link_whatsapp = `https://wa.me/?text=${text}`;
	let text_twitter = `Baixe o app e cadastre-se na Dois Vs Cashback utilizando o meu código ${codigo}.`;
	let link_twitter = `https://twitter.com/share?text=${text_twitter}&url=${url}`;

	return (
		<div className={!show ? "modal-hide" : "modal"}>
			<div className="center-box-modal" ref={_ref}>
				<div className="title-sharecode">Compartilhe seu código</div>
				<div>
					Convide seus amigos para se cadastrarem no aplicativo da{" "}
					<b>Dois Vs Cashback</b> utilizando seu código, e ganhe
					dinheiro nas compras que eles realizarem.
				</div>

				<div className="box-share-buttons">
					<a
						rel="noreferrer"
						href={link_whatsapp}
						target="_blank"
						data-action="share/whatsapp/share"
					>
						<span
							className="modal-icon ic-whats"
							title="Whatsapp"
						></span>
					</a>

					<a rel="noreferrer" href={link_twitter} target="_blank">
						<span
							className="modal-icon ic-twitter"
							title="Twitter"
						></span>
					</a>

					<div className="clear _10"></div>
				</div>
			</div>
		</div>
	);
};

export default ModalShare;
