export const formatDoc = (v) => {
	v = v.replace(/\D/g, "");
	v = v.replace(/(\d{3})(\d)/, "$1.$2");
	v = v.replace(/(\d{3})(\d)/, "$1.$2");
	v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

	return v;
};

export const formatCNPJ = (v) => {
	v = v.replace(/\D/g, "");
	v = v.replace(/^(\d{2})(\d)/, "$1.$2");
	v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
	v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
	v = v.replace(/(\d{4})(\d)/, "$1-$2");

	return v;
};

export const formatPhone = (num) => {
	var f = ("" + num).replace(/\D/g, "");

	f = f.replace(/(\d{2})(\d)/, "($1) $2");
	f = f.replace(/(\d{5})(\d)/, "$1 $2");

	return f;
};

export const formatCurrency = (num) => {
	var c = ("" + num).replace(/[\D]+/g, "");
	var l = c.length;
	var f = c.charAt(0);

	c = l === 1 && c !== "0" ? "0,0" + c : c;
	c = l === 1 ? c : c.replace(/([0-9]{2})$/g, ",$1");
	c = l === 2 && c !== "00" ? "0" + c : c;
	c = l > 3 && f === "0" ? c.replace("0", "") : c;

	if (l >= 6) {
		c = c.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
		c = c.replace(/(\d)(\d{3}),/g, "$1.$2,");
	}

	if (num === "NaN" || num === "0,0") {
		c = "";
	}

	return c;
};

export const generateUniqID = (q = 6) => {
	let qtd = q;

	if (q === 0) {
		qtd = Math.floor(Math.random() * (10 - 8 + 1)) + 8;
	}

	let chars = "0123456789";
	let numUniqID = "";
	let max = chars.length - 1;

	for (let i = 0; i < qtd; i++) {
		var mt_r = Math.floor(Math.random() * (max + 1));

		numUniqID += chars[mt_r];
	}

	return numUniqID;
};
