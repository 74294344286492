import React, { useEffect } from "react";

import "./styles.css";

import HeaderBar from "../HeaderBar";

import Footer from "../Footer";

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<HeaderBar page="sobre" />
			<div className="content-about">
				<div className="title-about">Sobre nós</div>
				<div className="text-about">
					<b>MISSÃO:</b> <br />
					Desenvolver uma rede de relacionamentos sólidos para
					prestação de serviços baseado no sistema de recompensas com
					qualidade e confiança, visando a satisfação dos parceiros,
					compradores e vendedores, contribuindo para o
					desenvolvimento e a melhoria contínua do comércio varejista
					e da qualidade de vida da população, respeitando o indivíduo
					e a sociedade.
					<br />
					<br />
					<b>VISÃO:</b>
					<br />
					Ser uma empresa reconhecida pela responsabilidade social,
					com notoriedade no mercado, e comprometida com o propósito
					de aumentar o poder de transformação das pessoas menos
					favorecidas.
					<br />
					<br />
					<b>VALORES:</b>
					<br />
					Responsabilidade social <br />
					Valorização humana <br />
					Ética <br />
					Comprometimento <br />
					Integridade <br />
					Melhoria contínua Superação de resultados <br />
					Sustentabilidade
				</div>
			</div>

			<Footer />
		</>
	);
};

export default About;
