import { NavLink, useParams } from "react-router-dom";

import "./styles.css";

import AppReportsExtract from "../../components/AppReportsExtract";
import AppReportsWithDraw from "../../components/AppReportsWithDraw";

const AppReports = () => {
	const params = useParams();
	const base = process.env.PUBLIC_APP_URL || "";
	let _base = base + "/app/relatorios";

	let subpath =
		params.subpath !== undefined && params.subpath !== ""
			? params?.subpath
			: "";

	const VoidLayout = () => {
		return <></>;
	};

	let Component = VoidLayout;
	let page = "";

	switch (subpath) {
		case "extrato":
			Component = AppReportsExtract;
			page = "Extrato de transações";
			break;

		case "resgates":
			Component = AppReportsWithDraw;
			page = "Resgates";
			break;

		default:
			Component = VoidLayout;
			break;
	}

	const Title = ({ page }) => {
		return (
			<div>
				{page === "" ? (
					<div className="u-title">Relatórios</div>
				) : (
					<div className="u-title">
						<span>
							<NavLink
								className="item-link-title"
								to={_base + ""}
							>
								Relatórios
							</NavLink>
						</span>
						&#160; › <span className="subpage">{page}</span>
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			<div className="main-component">
				<Title page={page} />
				<div className="clear _10"></div>
				<div className="submenu-component multiple-links">
					<NavLink
						className="item-link-component"
						to={_base + "/extrato"}
					>
						EXTRATO DE TRANSAÇÕES
					</NavLink>

					<NavLink
						className="item-link-component"
						to={_base + "/resgates"}
					>
						RESGATES
					</NavLink>
				</div>
				<div className="clear _20"></div>

				<Component />
			</div>
		</>
	);
};

export default AppReports;
