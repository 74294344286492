import { useContext, useEffect, useState } from "react";

import no_logo from "../../assets/no-logo.png";

import api from "../../utils/api";

import { formatPhone } from "../../utils/functions";

import { AuthContext } from "../../services/auth";

let arrProfile = [];

const AppConfigAccount = ({ company, email }) => {
	const { user } = useContext(AuthContext);

	const token = localStorage.getItem("token");

	const [avatar, setAvatar] = useState(no_logo);
	const [category, setCategory] = useState("");
	const [name, setName] = useState("");
	const [sponsor, setSponsor] = useState("");
	const [phone, setPhone] = useState("");
	const [list, setList] = useState([]);
	const [msgError, setMsgErr] = useState("");
	const [load, setLoad] = useState(false);

	useEffect(() => {
		const getPartnerData = async () => {
			const res = await api
				.get("partner/data", {
					headers: { token: token },
				})
				.catch((error) => {
					console.log(error?.response);
				});

			if (res) {
				let avatar = res?.data ? res?.data?.avatar : "no-logo.png";
				let path = "https://cdn.doisvs.com.br/partners/";
				let logo = path + avatar;
				let req_avatar = logo;

				setAvatar(req_avatar);
				setName(res?.data?.company);
				setSponsor(res?.data?.sponsor);
				setCategory(res?.data?.type);
			}
		};

		getPartnerData();
	}, [token]);

	useEffect(() => {
		const fillCategories = async () => {
			const res = await api.get("categories");
			setList(res.data);
		};
		fillCategories();
	}, []);

	const handleAccount = async () => {
		if (arrProfile.length === 0) {
			setMsgErr("Selecione a logo da sua empresa!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		}

		if (category === "" || name === "" || sponsor === "") {
			setMsgErr("Preencha os campos obrigatórios!");
			setLoad(false);

			setTimeout(() => {
				setMsgErr("");
			}, 3000);

			return;
		}

		const res = await api
			.post(
				"partner/update",
				{
					image: arrProfile,
					category: category,
					name: name,
					sponsor: sponsor,
					phone: phone,
				},
				{
					headers: { token: token },
				}
			)
			.catch((err) => {
				setLoad(false);
			});

		let status = res?.data?.status;
		let message = res?.data?.message;

		let _category = res?.data?.category;
		let _name = res?.data?.name;
		let _sponsor = res?.data?.sponsor;
		let _phone = res?.data?.phone;

		if (status === 1) {
			setCategory(_category);
			setName(_name);
			setSponsor(_sponsor);
			setPhone(_phone);

			setMsgErr(message);
		} else {
			setName(name);
			setSponsor(sponsor);
			setPhone(phone);

			setMsgErr(message);
		}

		setTimeout(() => {
			setMsgErr("");
		}, 3000);

		setLoad(false);
	};

	function q(elm, all) {
		if (all) {
			return document.querySelectorAll(elm);
		} else {
			return document.querySelector(elm);
		}
	}

	const handleUploadImage = (file) => {
		let selImg = q("#imgProfile");

		let FR = new FileReader();

		let files = selImg.files || file;
		let fileType = files.length >= 1 ? files[0].type : "";
		// let fileName = files.length >= 1 ? files[0].name : "";

		if (fileType === "image/jpeg" || fileType === "image/png") {
			FR.addEventListener("load", (e) => {
				if (q(".avatar")) {
					q(
						".avatar"
					).style.backgroundImage = `url(${e.target.result})`;
				}
				arrProfile.splice(0, 1, e.target.result);
			});
			FR.readAsDataURL(files.item(0));
		}
	};

	return (
		<>
			<div className="form-app zero-pad-top">
				<div className="logo-box">
					<div
						className="avatar"
						style={{
							backgroundImage: `url(${avatar})`,
						}}
					>
						<label htmlFor="imgProfile"></label>
					</div>
					<input
						type="file"
						id="imgProfile"
						name="imgProfile"
						className="file-type-upload"
						accept="image/jpeg,image/png"
						onChange={(e) => {
							handleUploadImage(e.target.files);
						}}
					/>
				</div>
				<div className="clear _30"></div>

				<div className="normal-box">
					<div className="title-item-form">CPF/CNPJ:</div>
					{user?.doc}
				</div>

				<div className="flex-box">
					<select
						name="category"
						onChange={(e) => {
							let v = e.target.value;
							setCategory(v);
						}}
						value={category}
					>
						<option value="null">* Segmento</option>
						{list.map((cat) => (
							<option key={cat.code} value={cat.slug}>
								{cat.title}
							</option>
						))}
					</select>

					<input
						name="name"
						type="text"
						placeholder="* Nome"
						autoComplete="off"
						defaultValue={name}
						onChange={(e) => {
							setName(e.target.value);
						}}
					/>
				</div>
				<div className="clear _10"></div>

				<div className="flex-box">
					<input
						name="sponsor"
						type="text"
						placeholder="* Responsável"
						autoComplete="off"
						defaultValue={sponsor}
						onChange={(e) => {
							setSponsor(e.target.value);
						}}
					/>

					<input
						name="email"
						type="text"
						className="disabled"
						placeholder="Email"
						autoComplete="off"
						defaultValue={user?.email}
						disabled={true}
					/>
				</div>
				<div className="clear _10"></div>

				<div className="flex-box">
					<input
						name="phone"
						type="text"
						placeholder="Telefone"
						autoComplete="off"
						value={phone}
						onChange={(e) => {
							let v = e.target.value;
							let _v = formatPhone(v);
							setPhone(_v);
						}}
					/>
				</div>
				<div className="clear _30"></div>

				<div
					className={
						msgError !== "" ? "errorBox-components" : "errorBoxHide"
					}
				>
					{msgError}
				</div>
				<button
					disabled={load}
					onClick={() => {
						setLoad(true);
						handleAccount();
					}}
				>
					SALVAR
				</button>
			</div>
		</>
	);
};

export default AppConfigAccount;
